import React from 'react';
import {Container, Title, InternContainer} from './styles';
import Item from './_partials/Item';

const Categories = ({content}) => {
  return (
    <Container className="mb-3 d-flex flex-column">
      {content &&
        content.length &&
        content.length > 0 &&
        content.map((categorie, index) => (
          <InternContainer key={index} className="d-flex flex-column pb-3 mb-4">
            <Title to={`/blog/${categorie.slug}` || '/'}>
              <button type="button" className="btn">
                {categorie.title}
              </button>
            </Title>
            {categorie.children &&
              categorie.children.length > 0 &&
              categorie.children.map((item, indexIntern) => (
                <Item className="categorie-item" key={indexIntern} content={item} />
              ))}
          </InternContainer>
        ))}
    </Container>
  );
};

export default Categories;
