import React from 'react';
import StripTags from 'striptags';
import {
  Container,
  TeacherImg,
  TeacherName,
  TeacherOf,
  ImageContainer,
  ImageInternContainer,
} from './styles';

const TeacherCard = ({content}) => {
  return (
    <Container
      className="d-flex flex-column align-items-center"
      to={`professores/${content.slug || ''}/${content.id}`}
      target={content.target || ''}>
      <ImageContainer>
        <ImageInternContainer>
          <TeacherImg src={content.avatar.url} alt="Teacher Photo" />
        </ImageInternContainer>
      </ImageContainer>
      <TeacherName>{content.name}</TeacherName>
      <TeacherOf dangerouslySetInnerHTML={{__html: StripTags(content.description)}} />
    </Container>
  );
};

export default TeacherCard;
