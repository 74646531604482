import React from 'react';
import {Link as LinkRouter} from 'react-router-dom';
import {hasExternalLink} from '../../../utils/utilities';

export const Link = ({to, action, children, className, onClick}) => {
  if (hasExternalLink(to)) {
    return (
      <a
        className={className || ''}
        href={to}
        target={action || '_self'}
        rel="noopener noreferrer"
        onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <LinkRouter className={className || ''} to={to} target={action || '_self'} onClick={onClick}>
      {children}
    </LinkRouter>
  );
};
