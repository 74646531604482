import React, {useEffect} from 'react';
import {Col, Container, Row} from 'reactstrap';

import {Helmet} from 'react-helmet';

import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {settings} from '../../config/settings';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import Title from '../../components/Title';
import {Box as ContentContainer, ProfileContainer} from './styled';

import PersonalData from './_partials/PersonalData';
import Subscription from './_partials/Subscription';
import MyEvolution from './_partials/MyEvolution';
// import LinkedAccounts from './_partials/LinkedAccounts';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3} padding="0 20px">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    marginBottom: 60,
  },
  tab: {
    fontWeight: 400,
    textTransform: 'initial',
  },
  indicator: {
    backgroundColor: '#36b5ab',
    width: 1,
    left: 0,
  },
  selected: {
    fontWeight: 'bold',
  },
  wrapper: {
    display: 'block',
    textAlign: 'start',
  },
  textColorInherit: {
    opacity: 1,
    color: '#222222',
  },
  tabRoot: {
    minHeight: 40,
    padding: '0 12px',
    marginBottom: 12,
  },
}));

const Profile = () => {
  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(settings.pageBase);
  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className="p-0">
      <Helmet>
        <title>
          {settings.titleSite}
          {`: Meus dados`}{' '}
        </title>
      </Helmet>
      <ProfileContainer>
        <Title className="title">Meus dados</Title>
        <Row noGutters className={classes.root}>
          <Col xs={3} sm={2} className="p-0 pl-2 pl-sm-0">
            <Tabs
              orientation="vertical"
              variant="standard"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{
                indicator: classes.indicator,
              }}
              indicatorColor="primary">
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selected,
                  wrapper: classes.wrapper,
                  textColorInherit: classes.textColorInherit,
                }}
                label="Dados Pessoais"
                disableTouchRipple
                className={classes.tab}
                {...a11yProps(0)}
              />
              <Tab
                label="Assinatura"
                className={classes.tab}
                disableTouchRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selected,
                  wrapper: classes.wrapper,
                  textColorInherit: classes.textColorInherit,
                }}
                {...a11yProps(1)}
              />
              <Tab
                label="Minha Evolução"
                className={classes.tab}
                disableTouchRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selected,
                  wrapper: classes.wrapper,
                  textColorInherit: classes.textColorInherit,
                }}
                {...a11yProps(2)}
              />
              {/*
                <Tab
                  label="Contas vinculadas"
                  className={classes.tab}
                  disableTouchRipple
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                    wrapper: classes.wrapper,
                    textColorInherit: classes.textColorInherit,
                  }}
                  {...a11yProps(3)}
                />
                */}
            </Tabs>
          </Col>

          <Col xs={9} sm={10} className="p-0">
            <ContentContainer>
              <TabPanel value={value} index={0}>
                <PersonalData />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Subscription />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MyEvolution />
              </TabPanel>
              {/*
                <TabPanel value={value} index={3}>
                  <LinkedAccounts />
                </TabPanel>
              */}
            </ContentContainer>
          </Col>
        </Row>
      </ProfileContainer>
    </Container>
  );
};

export default Profile;
