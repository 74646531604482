/* eslint-disable no-fallthrough */
import React from 'react';
import {StyledLink} from './styles';

const Attachments = ({attachment}) => {
  const type = attachment.file.url.split('.').pop();
  let icon;

  switch (type) {
    case 'pdf':
      icon = 'fas fa-file-pdf';
      break;
    case 'jpg':
      icon = 'fas fa-file-image';
    case 'jpeg':
      icon = 'fas fa-file-image';
    case 'png':
      icon = 'fas fa-file-image';
      break;
    default:
      icon = 'fas fa-file-pdf';
      break;
  }

  return (
    <StyledLink href={attachment.file.url} target="_blank">
      <i className={`${icon} ${type}`} />
      <span>{attachment.title}</span>
    </StyledLink>
  );
};

export default Attachments;
