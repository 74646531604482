import styled, {keyframes} from 'styled-components';
import {slideInUp} from 'react-animations';

export const Item = styled.div`
  display: block !important;
  position: relative;
  max-height: 75vh;
`;

export const BannerStyled = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .slick-dots {
    bottom: 10px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    font-size: 17px;
    color: white;
  }

  .slick-dots .slick-active button:before {
    opacity: 1;
  }
`;

export const BannerMask = styled.div`
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  @media (max-width: 575px) {
    max-height: 90vh;
  }
  @media (min-width: 576px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const PrevArrowStyled = styled.i`
  font-size: 60px;
  color: #ffffff;
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1.5em;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 575px) {
    font-size: 40px;
  }
`;

export const NextArrowStyled = styled(PrevArrowStyled)`
  right: 0;
`;

export const MetaInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
`;

export const MetaInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 575px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 750px;
  }
`;

const slideInUpAnimation = keyframes`${slideInUp}`;

const slideTeste = keyframes`
  from {
    opacity: 0;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
  }
  to {
    transform: translateY(150px);
  }
`;

export const Title = styled.h2`
  font-size: 78px;
  line-height: 67px;
  letter-spacing: 22px;
  text-transform: uppercase;
  text-align: center;
  margin-right: -22px;
  line-height: 125%;
  letter-spacing: 22px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 575px) {
    font-size: 40px;
    line-height: 40px;
  }
  margin-top: -240px;
  animation-name: ${slideTeste};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;
export const Description = styled.p`
  margin: 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  text-align: center;
  animation-name: ${slideTeste};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;
