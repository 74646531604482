import React from 'react';
import {KnowMore as More} from './styles';

const KnowMore = ({to, target, className, style, text}) => {
  return (
    <More className={className || ''} style={style || null} to={to || `/`} target={target}>
      {text || 'Saiba mais'}
      <i className="fal fa-long-arrow-right" />
    </More>
  );
};

export default KnowMore;
