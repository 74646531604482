import React from 'react';
import {Background} from './styles';

const Tag = ({children, className}) => {
  return (
    <>
      <Background className={className || ''}>{children}</Background>
    </>
  );
};

export default Tag;
