import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerVideo = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  margin: 12px 0;
  position: relative;
`;

export const ContainerImg = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100px;
  &:before {
    content: '';
    width: 100%;
    margin-bottom: 62.62%;
    height: 0;
    display: block;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
`;

export const TitleVideo = styled.p`
  margin-left: 10px;
  color: #333;
  font-size: 13px;
  text-decoration: none;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: Roboto, sans-serif;
  display: flex;
  margin-right: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  letter-spacing: 2px;
`;
export const Watching = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 3px solid rgba(0, 0, 0, 0.5);
  span {
    display: none;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      color: white;
      font-weight: bold;
      font-size: 18px;
    }
  }
`;
