import React from 'react';
import StripTags from 'striptags';
import {Row, Col} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import AnamneseBase from '../AnamneseBase';
import PlanCard from '../PlanCard';
import {PlansContainer, Title, Description} from './styles';
import {useAnamnese} from '../../contexts/Anamnese';
import {getCurrentUser} from '../../utils/auth';

const Plans = ({content, block}) => {
  const plans = content['hydra:member'];
  const {setSelectedPlan, setPlans} = useAnamnese();

  setPlans(plans);

  const isOdd = () => {
    return plans.length % 2 === 1;
  };

  const half = () => {
    // eslint-disable-next-line radix
    return parseInt(plans.length / 2, 10);
  };

  const onPlanSelected = (plan) => {
    setSelectedPlan(plan);
    // change to next page
  };

  const user = getCurrentUser();
  if (user.hasActiveSubscription) {
    return <Redirect to="/" />;
  }

  return (
    <AnamneseBase className="pb-5" background>
      <PlansContainer>
        <Title className="mt-5 mb-3">{block.title}</Title>
        <Description dangerouslySetInnerHTML={{__html: StripTags(block.description)}} />
        <Row noGutters className="d-flex justify-content-center align-items-end">
          {plans && plans.length && (
            <Col xs={10}>
              <Row noGutters className="d-flex justify-content-center align-items-end">
                {plans.map((plan, index) => (
                  <Col
                    xs={12}
                    md={4}
                    key={index}
                    className={`${isOdd && index === half() ? 'col-active' : ''}`}>
                    <PlanCard
                      content={plan}
                      onSeleted={onPlanSelected}
                      className={`${isOdd && index === half() ? 'big' : ''}`}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </PlansContainer>
    </AnamneseBase>
  );
};

export default Plans;
