import React from 'react';
import {Alternatives as AlternativesStyled} from './styles';

const Alternatives = ({handleChange, alternatives, allowedAnswers, questionId}) => {
  return alternatives.map((alternative) => (
    <AlternativesStyled key={`${questionId}-${alternative.id}`}>
      <input
        type={allowedAnswers > 1 ? 'checkbox' : 'radio'}
        name={
          allowedAnswers > 1
            ? `checkbox_question_${questionId}_${alternative.id}`
            : `radio_question_${questionId}`
        }
        value={`${questionId},${alternative.id}`}
        onChange={handleChange}
      />
      {alternative.title}
    </AlternativesStyled>
  ));
};

export {Alternatives};
