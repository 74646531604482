import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useCurrentPage, usePageBase} from '../contexts/PageContext';
import {settings} from '../config/settings';
import {getToken} from '../utils/auth';

const Pages = ({match}) => {
  const anamneseSlugs = ['planos', 'checkout', 'ficha-anamnese'];
  const {slug = getToken() ? settings.homeLogada : settings.home} = match.params;

  let page;
  if (anamneseSlugs.includes(slug)) {
    // se for um url do anamnese, nao carrega o menu principal
    page = settings.anamnesePage;
  } else {
    page = settings.pageBase;
  }

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(page);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrentPage(slug);
  }, [slug, setCurrentPage]);

  return null;
};

Pages.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]),
};

export default Pages;
