import React, {useEffect, useState} from 'react';
import {Container, Col, Row} from 'reactstrap';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import {settings} from '../../config/settings';
import {getPost, getAssociatedPosts} from '../../services/endpoints/blog/posts';
import BlogCard from '../../components/BlogCard';
import {ExternContainer, Category, Banner, TitlePost, Description, Instructor} from './styles';
import Title from '../../components/Title';
import Share from '../../components/Share';
import {getDate} from '../../utils/manipulateDates';
import noUserImage from '../../assets/images/noUser.png';

const BlogShow = ({match}) => {
  const {id} = match.params;
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  const [associatedPosts, setAssociatedPosts] = useState([]);

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(settings.pageBase);

  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        await getPost(id)
          .then((response) => {
            setPost(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Blog.Show', error);
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!post) {
      return;
    }
    const parameters = {
      perPage: 4,
      search: post.category.length > 0 ? post.category[0].slug : '',
    };
    getAssociatedPosts(parameters)
      .then((response) => {
        const relatedPosts = response.data['hydra:member'].filter(
          (item) => item['@id'] !== post['@id'],
        );
        setAssociatedPosts(relatedPosts.slice(0, 3));
      })
      .catch((error) => {
        console.error('Blog.Show', error);
      });
  }, [post]);

  return (
    <ExternContainer>
      <Container>
        {!loading ? (
          <>
            <Helmet>
              <title>{`${settings.titleSite}: ${post.title}`}</title>
              <meta name="description" content={post.title || ''} />
            </Helmet>
            <Title className="title">BLOG</Title>
            {post.category.length > 0 && <Category>#{post.category[0].title}</Category>}

            <TitlePost>{post.title}</TitlePost>
            {post.image && post.image.url && <Banner src={post.image.url} alt="Banner do Post" />}

            <Share
              className="share"
              title={post.title}
              url={window.location.href}
              media={post.image ? post.image.url : ''}
            />

            <Description dangerouslySetInnerHTML={{__html: post.content}} />
            <Instructor>
              <img
                src={
                  post.instructor && post.instructor.avatar
                    ? post.instructor.avatar.url
                    : noUserImage
                }
                alt="Instructor"
              />
              <div>
                <p>
                  Por:
                  <Link to={`/professores/${post.instructor.slug || ''}/${post.instructor.id}`}>
                    {post.instructor.name}
                  </Link>
                </p>
                <span>{getDate(post.createdAt)}</span>
              </div>
            </Instructor>
            {associatedPosts.length > 0 && (
              <>
                <Title className="title">RELACIONADOS</Title>
                <Row noGutters>
                  {associatedPosts.map((item, index) => (
                    <Col xs={12} md={4} key={index} className="pr-3">
                      <BlogCard content={item} noHome />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </>
        ) : (
          <Col xs={12} className="d-flex justify-content-center">
            <i className="far fa-spinner-third loading" />
          </Col>
        )}
      </Container>
    </ExternContainer>
  );
};

export default BlogShow;
