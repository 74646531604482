import React from 'react';
import {Container, Title, ContainerTitle, Price} from './styles';
import KnowMore from '../../../KnowMore';
import {priceFormat} from '../../../../utils/functions';

const SuggestedTrainingCard = ({content}) => {
  const price = content.planItems.reduce(
    (accumulator, planItem) => accumulator + planItem.price,
    0,
  );
  return (
    <Container>
      <ContainerTitle>
        <Title>{content.name}</Title>
        <Price>{priceFormat(price)}</Price>
      </ContainerTitle>
      <KnowMore className="know-more" to="/login" />
    </Container>
  );
};

export default SuggestedTrainingCard;
