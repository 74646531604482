import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {Container} from 'reactstrap';
import {Helmet} from 'react-helmet';
import GenerateForm from '../../components/GenerateForm';
import {FormStyled, ContactContainer, SendedContainer, Text} from './styles';
import {sendContact} from '../../services/endpoints/cms/contact_messages';

import Title from '../../components/Title';
import {settings as config} from '../../config/settings';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';

const Contact = () => {
  const [messageSended, setMessageSended] = useState(false);
  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(config.pageBase);
  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Seu nome',
          placeholder: 'Digite seu nome',
          hasPlaceholder: false,
          hasLabel: true,
          name: 'name',
          value: '',
          required: true,
          validations: {
            name: Yup.string().required('Campo obrigatório'),
          },
        },
        {
          label: 'E-mail',
          placeholder: 'Digite seu e-mail',
          hasPlaceholder: false,
          hasLabel: true,
          name: 'email',
          value: '',
          required: true,
          validations: {
            email: Yup.string().required('Campo obrigatório').email('Informe um email válido'),
          },
        },
        {
          label: 'Assunto',
          placeholder: 'Digite seu assunto',
          hasPlaceholder: false,
          hasLabel: true,
          name: 'subject',
          value: '',
          required: true,
          validations: {
            subject: Yup.string().required('Campo obrigatório'),
          },
        },
        {
          label: 'Mensagem',
          placeholder: 'Digite sua mensagem',
          hasPlaceholder: false,
          hasLabel: true,
          name: 'message',
          value: '',
          required: true,
          validations: {
            message: Yup.string().required('Campo obrigatório'),
          },
          type: 'textarea',
        },
      ],
    },
  ];
  const settings = {
    button: {
      text: 'Enviar mensagem',
      textSubmitting: 'Enviando...',
    },
  };

  const onSubmit = async (values, actions) => {
    try {
      await sendContact('contato', {
        ...values,
        newsletter: true,
      });

      const resetValues = {};
      Object.keys(values).forEach((key) => {
        Object.assign(resetValues, {[key]: ''});
      });

      actions.resetForm(resetValues);
      setMessageSended(true);
    } catch (e) {
      alert('Erro ao enviar formulário ');
    }

    actions.setSubmitting(false);
  };

  return (
    <ContactContainer>
      <Container className="d-block mb-4">
        <Helmet>
          <title>{`${config.titleSite}: Contato`}</title>
        </Helmet>
        <Title className="title">Contato</Title>
        {!messageSended ? (
          <FormStyled>
            <GenerateForm onSubmit={onSubmit} groupFields={groupFields} settings={settings} />
          </FormStyled>
        ) : (
          <SendedContainer>
            <Text>Obrigado! Sua mensagem foi enviada!</Text>
          </SendedContainer>
        )}
      </Container>
    </ContactContainer>
  );
};

export default Contact;
