import styled from 'styled-components';

export const PaymentContainer = styled.div`
  border: 1px solid #36b5ab;
  border-radius: 20px;
`;

export const Title = styled.h1`
  margin: 20px 0;
  padding: 0 10px;
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
`;

export const SelectedPlan = styled.label`
  margin-top: 40px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  display: block;
`;

export const FormStyled = styled.div`
  .element-field span {
    font-size: 14px;
  }
  .row {
    margin: 0;
  }

  input {
    display: block;
    width: 100%;
    padding: 13px 0;
    padding-right: 13px;
    font-size: 14px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #000 #000 hsla(0, 0%, 100%, 0.26);
    border-radius: 0px;
    color: #fff;
    margin-bottom: 13px;
    font-weight: 300;
    transition: border-color 265ms cubic-bezier(0.165, 0.84, 0.44, 1);
    font-family: Roboto, sans-serif;

    :hover {
      border-bottom-color: hsla(0, 0%, 100%, 0.56);
    }
    :focus {
      border-bottom-color: #fff;
    }
    ::placeholder {
      color: white;
      font-size: 16px;
    }
  }

  .footer-buttons {
    display: block;
    .btn {
      width: 100%;
      margin: 35px 0;
      :hover {
        background-color: #2d958d;
      }
    }
  }
`;

export const Bandeiras = styled.img`
  width: 100%;
`;

export const CodePromotional = styled.p`
  margin-top: 40px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
`;

export const CouponMessage = styled.div`
  color: white;
  background-color: green;
  padding: 20px;
  border-radius: 5px;
`;
