import React from 'react';
import PropTypes from 'prop-types';
import {sortBy} from '../../../utils/sortBy';
import ComponentFactory from '../../ComponentFactory';

const LoadBlocks = ({containers, locale, sort = 'position'}) => {
  return containers
    .filter(({container}) => container === locale)
    .sort(sortBy(sort))
    .map((block, index) => (
      <ComponentFactory
        key={`${locale}-${index}`}
        componentName={block.componentName}
        content={block.content}
        block={block.block}
      />
    ));
};

LoadBlocks.propTypes = {
  containers: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  sort: PropTypes.string,
};
export default LoadBlocks;
