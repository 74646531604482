import styled from 'styled-components';

export const ProfileContainer = styled.div`
  .title {
    color: #0b111e;
  }
`;

export const Box = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;
  width: fit-content;

  .MuiBox-root {
    padding: 0;
  }

  @media (min-width: 768px) {
    .MuiBox-root {
      padding: 0 20px;
    }
  }
`;

export const FormStyled = styled.div`
  width: 100%;
  .group-field {
    margin-bottom: 20px;

    > label {
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }

  .element-field {
    margin-bottom: 15px;

    .label-title {
      display: block;
      margin-bottom: 5px;
      letter-spacing: 2px;
    }

    .form-control:not([type='radio']) {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      padding: 8px 12px;
      display: block;
      width: 100%;
    }
    span {
      font-size: 14px;
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormStyledCard = styled(FormStyled)`
  .footer-buttons {
    display: flex;
    justify-content: flex-start;
  }
`;
export const FormStyledAccounts = styled(FormStyled)`
  .footer-buttons {
    display: flex;
    justify-content: center;
  }
`;
export const FormStyledEvolution = styled(FormStyled)`
  .row {
    margin: 0;
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    .footer-buttons {
      justify-content: center;
    }
  }
`;

export const InternTitle = styled.span`
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
`;

export const CardLinkedAccountsContainer = styled.div`
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #d6d6d6;
  border-radius: 4px;
  .row {
    margin: 0;
  }

  .avatar-content {
    margin-bottom: 0;
  }
`;

export const ActualData = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 6px;
`;

export const DeleteContainer = styled.div`
  margin-bottom: 15px;
  i {
    margin-right: 7px;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #52646d;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LabelShowEvolution = styled.span`
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 20px;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
`;

export const Info = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  margin: 5px 0;
  display: block;
  width: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;
