import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const SearchContainer = styled.div`
  .title {
    color: #0b111e;
  }
  min-height: 70vh;
`;

export const ContainerCard = styled(Link)`
  text-decoration: none;
  display: flex;
  margin-bottom: 15px;
  .blogType {
    background-color: green;
  }
  .serieType {
    background-color: #36b5ab;
  }
`;

export const CardType = styled.span`
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  height: fit-content;
`;

export const CardTitle = styled.h3`
  color: #333333;
`;

export const CardDescription = styled.span`
  color: #666666;
  word-wrap: break-word;
  line-height: 1.4em;
  max-height: 9.9em;
  overflow: hidden;
  text-overflow: ellipsis!important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
`;

export const CardIntern = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 85%;
`;

export const ButtonSingleSearch = styled(Link)`
  text-decoration: none;
  background-color: #0b111e;
  color: white;
  padding: 10px 25px;
  margin: 20px 0;
  display: inline-block;
  &:hover {
    background-color: rgba(11, 17, 30, 0.9);
  }
`;
