import React from 'react';
import {Col, Row, Container} from 'reactstrap';
import {ModalContainer, CloseModal} from './styles';
import PlanCard from '../PlanCard';
import {useAnamnese} from '../../../../contexts/Anamnese';

const PlansModal = ({close}) => {
  const {plans, setSelectedPlan} = useAnamnese();

  const onPlanSelect = (plan) => {
    setSelectedPlan(plan);
    close();
  };

  return (
    <ModalContainer className="d-flex justify-content-center align-items-center">
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <Row className="d-flex justify-content-center align-items-center flex-column">
          <Col xs={11} md={9} lg={7} className="mr-3">
            <CloseModal className="d-flex justify-content-end">
              <i className="far fa-times-circle" onClick={() => close()} />
            </CloseModal>
            {plans &&
              plans.length &&
              plans.map((plan, index) => (
                <PlanCard
                  key={index}
                  onSelect={onPlanSelect}
                  content={plan}
                  toggleModal={close}
                  modal
                  className="mb-2"
                />
              ))}
          </Col>
        </Row>
      </Container>
    </ModalContainer>
  );
};

export default PlansModal;
