import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import BlockTitle from '../Title';
import Card from '../CourseCard';
import {CourseContainer, ExternContainer} from './styles';

const Recent = ({content, block}) => {
  const courses = content['hydra:member'] || [];

  const GridForFive = () => {
    const firstCourse = courses[0];
    const lastCourses = courses.slice(1, 5);

    return (
      <>
        <Col xs={12} md={6} key={1} className="pr-3 mb-2 mb-1">
          <Card content={firstCourse} home />
        </Col>
        <Col xs={12} md={6} key={2} className="">
          <Row noGutters>
            {lastCourses.map((course, index) => (
              <Col xs={12} md={6} key={index} className="px-5 px-md-0 pr-md-3 pb-2">
                <Card content={course} home />
              </Col>
            ))}
          </Row>
        </Col>
      </>
    );
  };

  return (
    <ExternContainer>
      <Container>
        <BlockTitle>{block.title}</BlockTitle>
        <CourseContainer>
          <Row noGutters>
            {courses.length === 5
              ? GridForFive()
              : courses.map((course, index) => (
                  <Col xs={12} md={6} lg={4} key={index} className="pr-3 mb-3">
                    <Card content={course} home />
                  </Col>
                ))}
          </Row>
        </CourseContainer>
      </Container>
    </ExternContainer>
  );
};

export default Recent;
