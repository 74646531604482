import React from 'react';
import {Container, ContainerImg, TitleVideo, ContainerVideo, Title, Watching} from './styles';
import NoImage from '../../../../assets/images/noImagePost.jpeg';

const Playslit = ({collection = [], onPlaylistClick, currentEpisode}) => {
  return (
    <Container className="mt-3 mt-md-0">
      <Title>Próximos vídeos deste curso</Title>
      {collection.map((video, index) => (
        <ContainerVideo key={index} onClick={() => onPlaylistClick(video)}>
          {currentEpisode === video && (
            <Watching>
              <span>Assistindo</span>
            </Watching>
          )}
          <ContainerImg>
            <img src={video.image ? video.image.url : NoImage} alt="thumbnail" />
          </ContainerImg>
          <TitleVideo>{video.title}</TitleVideo>
        </ContainerVideo>
      ))}
    </Container>
  );
};

export default Playslit;
