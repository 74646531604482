import React from 'react';
import StripTags from 'striptags';
import {Container} from 'reactstrap';
import {Container as IframeContainer, LiveContainer, Iframe, Message} from './styles';

const Live = ({content}) => {
  return (
    <LiveContainer className="mt-4 pb-5 d-flex justify-content-center">
      <Container className="px-2 px-lg-0">
        {content.link ? (
          <IframeContainer>
            <Iframe src={content.link} />
          </IframeContainer>
        ) : (
          <Message dangerouslySetInnerHTML={{__html: StripTags(content.description)}} />
        )}
      </Container>
    </LiveContainer>
  );
};

export default Live;
