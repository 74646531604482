import styled from 'styled-components';

export const Title = styled.h2`
  color: white;
  letter-spacing: 12px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 20px 0;
`;
