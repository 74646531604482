import styled from 'styled-components';

export const ProfileContainer = styled.div`
  .title {
    color: #0b111e;
  }
`;

export const Box = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;
  width: fit-content;
  width: 100%;

  .MuiBox-root {
    padding: 0;
  }

  & > div {
    width: 100%;
  }
`;

export const InternTitle = styled.span`
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
`;

export const CardLinkedAccountsContainer = styled.div`
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #d6d6d6;
  border-radius: 4px;
  .row {
    margin: 0;
  }

  .avatar-content {
    margin-bottom: 0;
  }
`;

export const ActualData = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-right: 32px;
  margin-top: 6px;
`;

export const DeleteContainer = styled.div`
  margin-bottom: 15px;
  i {
    margin-right: 7px;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #52646d;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LabelShowEvolution = styled.span`
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 20px;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
`;

export const Info = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  margin: 5px 0;
  display: block;
  width: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .progress {
    width: 100%;
  }
`;
export const IconCard = styled.i`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  color: #333;
`;

export const Empty = styled.span`
  font-size: 20px;
  color: #333;
  margin-top: 5px;
  font-weight: bold;
`;

