/* eslint-disable no-unreachable */
import React, {useState} from 'react';
import * as Yup from 'yup';
import StripTags from 'striptags';
import axios from 'axios';
import {Modal} from '@material-ui/core';
import {Row, Col, Container} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {useAlert} from 'react-alert';
import validarCpf from 'validar-cpf';
import AnamneseBase from '../AnamneseBase';
import PlanCard from './_partials/PlanCard';
import GenerateForm from '../GenerateForm';
import PlansModal from './_partials/PlansModal';
import {
  Title,
  SelectedPlan,
  FormStyled,
  Bandeiras,
  CodePromotional,
  PaymentContainer,
  CouponMessage,
} from './styles';
import {useAnamnese} from '../../contexts/Anamnese';
import api from '../../services/api';
import {priceFormat, onlyNumbers, validCard} from '../../utils/functions';
import {vindiErrors} from '../../utils/vendi_help';
import {getCurrentUser} from '../../utils/auth';

const AnamnesePayment = ({content, block}) => {
  const alert = useAlert();
  const [showCoupomMessage, setShowCoupomMessage] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');
  const [validCoupon, setValidCoupon] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const {selectedPlan} = useAnamnese();
  const [redirectAnamnese, setRedirectAnamnese] = useState(false);

  const toggleModal = () => setOpenModal((prev) => !prev);

  const getSeletedPlan = () => (
    <Col xs={12}>
      <SelectedPlan>Plano escolhido</SelectedPlan>
      <PlanCard content={selectedPlan} toggleModal={toggleModal} />
    </Col>
  );

  const getCupomComponentTitle = () => (
    <Col xs={12}>
      <CodePromotional>Possui código promocional?</CodePromotional>
    </Col>
  );

  const getCupomComponent = () => (
    <>
      <Col xs={12}>
        {showCoupomMessage && (
          <CouponMessage
            style={{backgroundColor: validCoupon ? 'green' : 'red'}}
            dangerouslySetInnerHTML={{__html: StripTags(couponMessage)}}
          />
        )}
      </Col>
      <Row noGutters className="d-flex justify-content-end">
        <Col xs={8} md={4}>
          <Bandeiras src={content.image.url} alt="bandeiras aceitas" />
        </Col>
      </Row>
    </>
  );

  const fieldsPayment = [
    {
      name: '',
      fields: [
        {
          component: getSeletedPlan,
        },
        {
          component: getCupomComponentTitle,
        },
        {
          hasLabel: false,
          label: 'Digite o seu código de cupom',
          hasPlaceholder: true,
          placeholder: 'Digite o seu código de cupom',
          name: 'cupom',
          value: '',
          required: false,
          columns: {
            xs: 12,
          },
        },
        {
          component: getCupomComponent,
        },
        {
          hasLabel: false,
          label: 'CPF',
          hasPlaceholder: true,
          placeholder: 'Digite seu CPF',
          name: 'identity',
          value: '',
          required: true,
          validations: {
            identity: Yup.string()
              .required('Campo obrigatório')
              .typeError('CPF não pode estar vazio'),
          },
          mask: '999.999.999-99',
          maskType: 'IDENTITY',
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: false,
          label: 'Número do Cartão',
          hasPlaceholder: true,
          placeholder: 'Número do cartão',
          name: 'card_number',
          value: '',
          required: true,
          validations: {
            card_number: Yup.string().required('Campo obrigatório').max(19, 'Máximo de 16 digitos'),
          },
          mask: '9999 9999 9999 9999',
          maskType: 'CARD_NUMBER',
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: false,
          label: 'Nome do Cartão',
          hasPlaceholder: true,
          placeholder: 'Nome do cartão',
          name: 'card_name',
          value: '',
          required: true,
          validations: {
            card_name: Yup.string().required('Campo obrigatório'),
          },
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: false,
          label: 'MM/AA',
          hasPlaceholder: true,
          placeholder: 'MM/AA',
          name: 'card_validity',
          value: '',
          required: true,
          validations: {
            card_validity: Yup.string()
              .required('Campo obrigatório')
              .min(5, 'Data de validade inválida')
              .max(5, 'Data de validade inválida'),
          },
          mask: '99/99',
          maskType: 'VALIDITY',
          columns: {
            xs: 12,
            md: 6,
          },
        },
        {
          hasLabel: false,
          label: 'CVV',
          hasPlaceholder: true,
          placeholder: 'CVV',
          name: 'security_code',
          value: '',
          required: true,
          validations: {
            security_code: Yup.string()
              .required('Campo obrigatório')
              .min(3, 'CVV inválido')
              .max(4, 'CVV inválido'),
          },
          mask: '9999',
          maskType: 'SECURITY_CODE',
          columns: {
            xs: 12,
            md: 6,
          },
        },
      ],
    },
  ];

  const settingsForm = {
    button: {
      text: 'Iniciar assinatura',
    },
  };

  const onSubmit = async (values, actions) => {
    if (values.identity) {
      // valida cpf
      const identity = onlyNumbers(values.identity);
      if (!validarCpf(identity)) {
        alert.show('CPF inválido. Insira um CPF válido e tente novamente.', {
          closeCopy: 'Ok',
        });
        actions.setSubmitting(false);
        return;
      }
    }

    if (values.card_validity) {
      // valida validade do cartao
      if (!validCard(values.card_validity)) {
        alert.show('Cartão vencido. Insira um cartão válido.', {
          closeCopy: 'Ok',
        });
        actions.setSubmitting(false);
        return;
      }
    }

    if (values.cupom) {
      // valida cupom
      // try catch do COUPON
      try {
        const responseCoupom = await api.post('/coupons/validate', {
          code: values.cupom,
        });

        const {data} = responseCoupom;
        const {benefits} = data;

        setCouponId(data['@id']);

        let message = '<p>Cupom ativado com sucesso.</p>';
        message += '<p><b>Benefícios:</b></p>';
        let benefitsMessage = `${message}<ul>`;

        // eslint-disable-next-line array-callback-return
        Object.keys(benefits).map((key) => {
          switch (key) {
            case 'amount':
              benefitsMessage += `<li>Desconto de ${priceFormat(benefits.amount)}.</li>`;
              break;
            case 'trial':
              benefitsMessage += `<li>${benefits.trial} dias grátis.</li>`;
              break;
            case 'products':
              benefitsMessage += `<li>Acesso aos produtos a seguir:<ol>`;
              benefits.products.forEach((product) => {
                benefitsMessage += `<li>${product}.</li>`;
              });
              benefitsMessage += `</ol></li>`;
              break;
            case 'percentage':
              benefitsMessage += `<li>Desconto de ${parseInt(benefits.percentage, 10)}%</li>`;
              break;
            default:
              benefitsMessage += '';
              break;
          }
        });
        benefitsMessage += '</ul>';
        setCouponMessage(benefitsMessage);
        setValidCoupon(true);
        setShowCoupomMessage(true);
        actions.setSubmitting(false);
        // eslint-disable-next-line no-unreachable
      } catch (error) {
        actions.setSubmitting(false);
        setValidCoupon(false);
        setCouponMessage(error.response.data['hydra:description']);
        setShowCoupomMessage(true);
        setCouponId(null);
      }
      return;
    }

    await api
      .get('/settings?section=vindi')
      .then((response) => {
        const data = response.data['hydra:member'];
        // eslint-disable-next-line prefer-const
        let settings = [];
        data.forEach((setting) => {
          settings[setting.name] = setting.value;
        });

        if (settings.vindi_api_url && settings.vindi_public_key) {
          const vindiPublicKey = btoa(settings.vindi_public_key);

          axios
            .post(
              `${settings.vindi_api_url}public/payment_profiles`,
              {
                holder_name: values.card_name.trim().toUpperCase(),
                card_expiration: values.card_validity.trim(),
                card_number: onlyNumbers(values.card_number),
                card_cvv: onlyNumbers(values.security_code),
                payment_method_code: 'credit_card',
              },
              {
                headers: {
                  Authorization: `Basic ${vindiPublicKey}`,
                },
              },
            )
            .then((paymentResponse) => {
              const options = {
                planItem: selectedPlan.planItems[0]['@id'],
                customerIdentity: onlyNumbers(values.identity),
                customerToken: paymentResponse.data.payment_profile.gateway_token,
                paymentMethod: 'credit_card',
                installments: 1,
              };

              if (values.cupom) {
                Object.assign(options, {
                  coupon: values.cupom,
                });
              }

              api
                .post('/checkout', {
                  ...options,
                })
                .then((resp) => {
                  if (resp.data.status === 'fail') {
                    api
                      .post('/checkout', {
                        ...options,
                      })
                      .then(() => {
                        actions.setSubmitting(false);
                        setRedirectAnamnese(true);
                      })
                      .catch(() => {
                        actions.setSubmitting(false);
                        alert.show('Ocorreu um erro ao realizar a assinatura', {
                          closeCopy: 'Ok',
                        });
                      });
                  } else {
                    actions.setSubmitting(false);
                    setRedirectAnamnese(true);
                  }
                })
                .catch(() => {
                  actions.setSubmitting(false);
                  alert.show('Ocorreu um erro ao realizar a assinatura', {
                    closeCopy: 'Ok',
                  });
                });
            })
            .catch((error) => {
              alert.show(vindiErrors(error), {
                closeCopy: 'Ok',
              });
              // eslint-disable-next-line no-console
              console.log('handleSubmit.vindiPayment', error);
              actions.setSubmitting(false);
            });
        } else {
          alert.show('Estamos com problemas o realizar o pagamento, tente novamente mais tarde.', {
            closeCopy: 'Ok',
          });
          // eslint-disable-next-line no-console
          console.log('handleSubmit.vindi', 'vindi public key not found');
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('handleSubmit.settings', error);
        actions.setSubmitting(false);
      });
  };

  if (!selectedPlan) {
    return <Redirect to="/planos" />;
  }

  if (redirectAnamnese) {
    const user = getCurrentUser();
    if (user.hasCompletedAnamnesisForm) {
      return <Redirect to="/" />;
    }
    return <Redirect to="/ficha-anamnese" />;
  }

  return (
    <>
      <AnamneseBase>
        <Container>
          <Modal
            open={openModal}
            onClose={toggleModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <PlansModal close={toggleModal} />
          </Modal>
          <Row noGutters className="d-flex justify-content-center">
            <Col xs={12} md={10} lg={7}>
              <PaymentContainer className="py-3 px-4">
                <Title>{block.title}</Title>
                <FormStyled>
                  <GenerateForm
                    onSubmit={onSubmit}
                    groupFields={fieldsPayment}
                    settings={settingsForm}
                  />
                </FormStyled>
              </PaymentContainer>
            </Col>
          </Row>
        </Container>
      </AnamneseBase>
    </>
  );
};

export default AnamnesePayment;
