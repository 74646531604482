/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {InternTitle, Empty} from '../styled';
import {getLikes} from '../../../services/endpoints/courses/courses';
import LikedCard from './LikedCard';

const Liked = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const reload = () => {
    setLoading(true);
    getLikes()
      .then((response) => {
        setCourses(response.data['hydra:member']);
        setLoading(false);
      })
      .catch((error) => {
        console.error('LOADING.LIKED.SERIES', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, []);

  return (
    <Row noGutters>
      <Col xs={12} className="p-0">
        <InternTitle>Todos seus cursos curtidos</InternTitle>
      </Col>
      {!loading ? (
        courses.length > 0 ? (
          courses.map((item, index) => (
            <Col xs={12} md={6} lg={4} key={index} className="mb-3 px-0 px-md-2">
              <LikedCard content={item.serie} reload={reload} />
            </Col>
          ))
        ) : (
          <Empty>Vazio</Empty>
        )
      ) : (
        <Col xs={12} className="d-flex justify-content-center my-5">
          <i className="far fa-spinner-third loading" />
        </Col>
      )}
    </Row>
  );
};

export default Liked;
