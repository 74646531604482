import React, {useEffect, useState} from 'react';
import Script from 'react-load-script';
import {getSettings} from '../../../../services/endpoints/general/settings';
import {setViewProgress} from '../../../../services/endpoints/courses/courses';
import {getToken} from '../../../../utils/auth';

const SambaVideoPlaylist = ({currentEpisode, currentSerie}) => {
  const [loadScript, setLoadScript] = useState(false);
  const [playerKey, setPlayerKey] = useState('');
  const player = 'player';
  let sambaPlayer = false;
  let setProgress = false;

  useEffect(() => {
    getSettings()
      .then((res) => {
        const settings = res.data['hydra:member'];
        const playerKeyFounded = settings.find(
          (setting) => setting.name === 'sambavideos_playerKey',
        );
        setPlayerKey(playerKeyFounded.value);
      })
      .catch((err) => {
        console.error('Erro ao buscar settings', err);
      });
  }, []);

  if (loadScript && playerKey) {
    const eventListener = (player) => {
      switch (player.event) {
        case 'onLoad':
          break;
        case 'onStart':
          break;
        case 'onListen':
          if (
            !!getToken() &&
            !setProgress &&
            player.eventParam > 1 &&
            parseInt(player.eventParam, 10) % 15 === 0
          ) {
            setProgress = true;
            const aux = (player.eventParam * 100) / (player.duration / 1000);
            setViewProgress({
              progress: player.eventParam,
              percent: parseInt(aux, 10),
              episode: `${currentEpisode['@id']}`,
            });
            setTimeout(() => {
              setProgress = false;
            }, 1000);
          }
          break;
        case 'onPause':
          break;
        case 'onResume':
          break;
        case 'onSeek':
          break;
        case 'onCuepoint':
          break;
        case 'onMediaView':
          break;
        case 'onProgress':
          break;
        case 'onFinish':
          if (getToken()) {
            setViewProgress({
              progress: player.duration / 1000,
              percent: 100,
              episode: `${currentEpisode['@id']}`,
            });
          }
          break;
        case 'onError':
          break;
        default:
          break;
      }
    };

    if (!sambaPlayer) {
      sambaPlayer = new window.SambaPlayer(player, {
        ph: playerKey,
        m: currentEpisode.reference,
        playerParams: {
          enableShare: false,
          resume: true,
          enableControls: ['seekbar', 'settings', 'fullscreen', 'play', 'time', 'volume'],
        },
        events: {
          '*': eventListener,
        },
      });
    }
  }

  return (
    <>
      {currentEpisode && (
        <div className="items">
          <Script
            url="https://player.sambatech.com.br/v3/samba.player.api.js"
            onLoad={() => setLoadScript(true)}
          />
          <div className="embed-responsive embed-responsive-16by9 mb-30">
            <div className="embed-responsive-item" id="player" />
          </div>
        </div>
      )}
    </>
  );
};

export default SambaVideoPlaylist;
