import styled from 'styled-components';

export const Background = styled.div`
  display: content;
  background-color: #36b5ab;
  padding: 10px 8px;
  border-bottom-left-radius: 8px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  right: 0px;
  top: 0px;
  letter-spacing: 4px;
  z-index: 1;
  text-transform: uppercase;
  color: #18181d;
`;
