import React, {useEffect, useState, useRef} from 'react';
import {Row, Col, Container} from 'reactstrap';
import PlanCard from './_partials/PlanCard';
import {ContainerPlan} from './styles';
import TitleWhite from '../BlogTitle';
import Treino from '../../assets/images/treino.png';

const SuggestedTraining = ({content, block}) => {
  const [animation, setAnimation] = useState(false);
  const element = useRef(null);
  const plans = content['hydra:member'];

  const onScroll = () => {
    if (!element.current) {
      return;
    }
    const activeAnimation =
      element.current.offsetTop <=
      document.documentElement.scrollTop + document.documentElement.clientHeight - 100;

    if (activeAnimation) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  return (
    <ContainerPlan ref={element}>
      <Container className="px-4 px-lg-0">
        <Row className="row p-0">
          <Col
            xs={12}
            lg={8}
            xl={7}
            className={`align-self-center items ${animation ? 'animation-right' : ''}`}>
            <Row noGutters className="mb-5">
              <TitleWhite>{block.title}</TitleWhite>
            </Row>
            {plans &&
              plans.length &&
              plans.map((item, index) => (
                <Row noGutters key={index} className="mb-2">
                  <PlanCard content={item} />
                </Row>
              ))}
          </Col>
          <Col xs={12} lg={4} xl={5} className="shape-container d-flex justify-content-center">
            <img
              className={`shape ${animation ? 'animation-left' : ''}`}
              src={Treino}
              alt="shape-celular"
            />
          </Col>
        </Row>
      </Container>
    </ContainerPlan>
  );
};

export default SuggestedTraining;
