import styled from 'styled-components';

export const ElementField = styled.div`
  & span {
    color: red !important;
  }
`;

export const ElementLabel = styled.label`
  margin-top: 20px;
  margin-bottom: 0;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
`;
