import styled from 'styled-components';

export const ContainerApp = styled.div`
  background-color: #0b111e;
  padding-top: 60px;
`;

export const Description = styled.p`
  padding-top: 10px;
  padding-bottom: 24px;
  color: white;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
`;

export const ContainerStore = styled.div`
  margin: 10px 0;
`;

export const StoreImg = styled.img`
  height: 50px;
`;

export const ImgApp = styled.img`
  max-width: 80%;
  object-fit: contain;
`;
