import React from 'react';
import {Row, Col} from 'reactstrap';
import {Item} from './styles';
import TitleMenuFooter from '../TitleMenuFooter';

const MenuFooter = ({content, block}) => {
  return (
    <Col xs={6} className="p-0">
      <Row noGutters className="p-0 d-flex flex-column">
        <TitleMenuFooter>{block.title}</TitleMenuFooter>
        {content &&
          content.menuItems.length &&
          content.menuItems.map((item, index) => (
            <Item key={index} to={item.url} target={item.target}>
              {item.title}
            </Item>
          ))}
      </Row>
    </Col>
  );
};

export default MenuFooter;
