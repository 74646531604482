import React from 'react';
import SubscriptionArea from './SubscriptionArea';
import CardArea from './CardArea';

const SubscriptionAndCard = () => {
  return (
    <>
      <SubscriptionArea />
      <CardArea />
    </>
  );
};

export default SubscriptionAndCard;
