import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
// import api from '../api';
import {getAsyncCurrentUser, setAsyncCurrentUser, getToken} from '../../utils/auth';
import {getMe} from '../endpoints/general/currentUser';
import {getEndpoints} from '../endpoints/getEndpoints';

const AuthProvider = ({children}) => {
  const [state, setState] = useState({
    loading: false,
    user: undefined,
  });

  const history = useHistory();

  async function load() {
    try {
      if (getToken()) {
        const response = await getMe();
        const res = await getEndpoints(response.data.user['@id']);

        await setAsyncCurrentUser({...response.data, profile: res.data});
        const currentUser = await getAsyncCurrentUser();

        if (response.data.user['@type'] === 'Customer') {
          if (
            !response.data.hasActiveSubscription &&
            !(
              window.location.href.indexOf('/planos') !== -1 ||
              window.location.href.indexOf('/checkout)') !== -1 ||
              window.location.href.indexOf('/ficha-anamnese') !== -1
            )
          ) {
            history.push(`/planos?noplan=${btoa(window.location.href)}`);
          }
        }

        setState({
          loading: true,
          user: currentUser,
        });
      } else {
        setState({
          loading: true,
          user: {},
        });
      }
    } catch (e) {
      console.error('AuthProvider', e);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return state.loading && state.user ? (
    children
  ) : (
    <div className="mt-30 mb-30 text-center">{/* Loading... */}</div>
  );
};

export default AuthProvider;
