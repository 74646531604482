import styled from 'styled-components';

export const SeachButtonLink = styled.button`
  background-color: transparent;
  border: none;
  border-left: 1px solid #0b111e;
  color: #0b111e;
  padding: 0 20px;
  text-transform: initial;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0b111e;
    color: white;
  }
`;

export const ContainerFormDesktop = styled.form`
  width: 100%;
  display: flex;
`;

export const InputDesktop = styled.input`
  border: none;
  background-color: #fff;
  color: #0b111e;
  padding: 0 15px;
  font-size: 14px;
  width: 100%;
  outline: none;
  height: 40px;
  padding: 25px 20px;
  font-weight: 600;
  &::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
    font-weight: bold;
    @media (max-width: 991px) {
      color: #0b111e;
    }
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
    font-weight: bold;
    @media (max-width: 991px) {
      color: #0b111e;
    }
  }

  &::placeholder {
    color: #0b111e;
    opacity: 0.65;
    font-weight: 400;
    @media (max-width: 991px) {
      color: #0b111e;
    }
  }
`;

export const FormStyle = styled.div`
  width: 100%;
  @media (max-width: 992px) {
    position: absolute;
    top: 0;
  }
  & input {
    border: none;
    background-color: #fff;
    color: #0b111e;
    padding: 0 15px;
    font-size: 14px;
    width: 100%;
    outline: none;
    height: 40px;
    padding: 25px 20px;
    font-weight: 600;
    @media (max-width: 991px) {
      color: #0b111e;
      padding-bottom: 0px;
      margin-top: 10px;
      border: 1px solid #0b111e;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: #fff;
      font-weight: bold;
      @media (max-width: 991px) {
        color: #0b111e;
      }
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
      font-weight: bold;
      @media (max-width: 991px) {
        color: #0b111e;
      }
    }

    &::placeholder {
      color: #0b111e;
      opacity: 0.65;
      font-weight: 400;
      @media (max-width: 991px) {
        color: #0b111e;
      }
    }
  }
`;
export const ContainerSearchStyle = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 54px;
  z-index: 9999999;
  background-color: #fff;
  font-family: 'HelveticaLTStd', sans-serif;
  font-weight: 100;
  width: 500px;
  border: 1px solid #0b111e;
  right: -1px;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 991px) {
    position: relative;
    top: 0;
    padding: 0;
    opacity: 1;
    visibility: visible;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0;
    background-color: #fff;
    width: 100%;
    margin: 0 10px;
    & form {
      width: 100%;
    }
    & input {
      background-color: transparent;
      width: 100%;
    }
  }
`;
export const SearchIcon = styled.i`
  color: #eee;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-right: 40px;

  & .search-box.open {
    opacity: 1;
    visibility: visible;
  }
  &:hover,
  &.open {
    background-color: #fff;
    color: #0b111e;
    & .search-box {
      opacity: 1;
      visibility: visible;
    }
  }
  @media (max-width: 991px) {
    color: #0b111e;
    &:before {
      display: none;
    }
    &:hover,
    &.open {
      background-color: #fff;
      color: #0b111e;
    }
  }
`;
