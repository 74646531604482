const translate = (str) => {
  switch (str) {
    case 'phone':
      return 'Telefone';
    case 'identity':
      return 'CPF';
    case 'phones.number':
      return 'Telefone';
    case 'phones':
      return 'Telefone';
    case 'phoneNumber':
      return 'Telefone';

    case 'holder_name':
      return 'Nome do cartão';
    case 'card_number':
      return 'Número do cartão';
    case 'card_cvc':
      return 'CVC';
    case 'card_expiration':
      return 'MM/AA';
    case 'payment_company_id':
      return 'Banco';
    case 'payment_company_code':
      return 'Código da Bandeira';
    default:
      return '';
  }
};

export const vindiErrors = (error) => {
  const {status, data} = error.response;

  switch (status) {
    case 401:
      return 'Requisição não autorizada';
    case 422:
      if (data.errors) {
        let message = '';
        data.errors.forEach((e) => {
          message += `${translate(e.parameter)}: ${e.message}\n`;
        });

        return message;
      }
      if (data['hydra:description']) {
        return data['hydra:description'];
      }
      return data['Ocorreu um erro, tente novamente mais tarde'];

    default:
      return data['Ocorreu um erro, tente novamente mais tarde'];
  }
};
