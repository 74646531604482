import React, {createContext, useContext, useState} from 'react';

export const PageContext = createContext({});

export default function PageProvider({children}) {
  const [pageBase, setPageBase] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  return (
    <PageContext.Provider
      value={{
        pageBase,
        setPageBase,
        currentPage,
        setCurrentPage,
      }}>
      {children}
    </PageContext.Provider>
  );
}

// Define hooks
export function usePageBase() {
  const context = useContext(PageContext);
  if (!context) throw new Error('usePageBase must be used within a PageProvider');
  const {pageBase, setPageBase} = context;

  return {pageBase, setPageBase};
}

export function useCurrentPage() {
  const context = useContext(PageContext);
  if (!context) throw new Error('usePageBase must be used within a PageProvider');
  const {currentPage, setCurrentPage} = context;

  return {currentPage, setCurrentPage};
}
