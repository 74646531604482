import styled from 'styled-components';

export const ContainerBlog = styled.div`
  height: 550px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(180deg, transparent, #0b111e),
    linear-gradient(180deg, #0b111e 14%, transparent 52%),
    url(https://assets.website-files.com/5ebab15f00de7426d732dd15/5ed560373cf77c3e5385f04c_BANNERV2-03.jpg);
  background-position: 0px 0px, 0px 0px, 0px 0px;
  background-size: auto, auto, cover;

  padding: 100px 0;
`;

export const SubTitle = styled.h1`
  width: 80%;
  margin: 10px 0;
  padding: 0;
  color: white;
  font-weight: 300;
  font-size: 44px;
  line-height: 52px;
`;

export const PrevArrowStyled = styled.i`
  font-size: 45px;
  color: #36b5ab;
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 1.5em;
  left: -1.5em;
  justify-content: center;
  cursor: pointer;
`;

export const NextArrowStyled = styled(PrevArrowStyled)`
  left: initial;
  right: -1.5em;
`;

export const ContainerSlider = styled.div`
  max-height: 300px;
  width: 100%;

  .know-more {
    margin-bottom: 0;
  }
`;
