import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ItensContainer = styled.div``;

export const CloseModal = styled.div`
  width: 100%;
  margin-bottom: 20px;
  i {
    font-size: 25px;
    color: white;
    cursor: pointer;
  }
`;
