import React from 'react';
import {Container} from 'reactstrap';
import logo from '../../assets/images/logo.png';
import {AnamneseContainer, ContainerLogo, Logo, BackgroundImg} from './styles';

const Pages = ({children, className = '', background}) => {
  return (
    <AnamneseContainer>
      {background && <BackgroundImg />}

      <Container className={`index-1 ${className}`}>
        <ContainerLogo>
          <Logo src={logo} alt="Logo Meu Shape" />
        </ContainerLogo>
        {children}
      </Container>
    </AnamneseContainer>
  );
};

export default Pages;
