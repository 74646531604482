import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justity-content: center;
  p {
    margin: 0;
  }
`;
export const BackgroundButton = styled.div`
  margin-left: 10px;
  border-radius: 5px;
  background-color: blue;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 100%;
    width: 100%;
  }
  i {
    color: white;
    font-size: 16px;
  }
`;
