import styled from 'styled-components';

export const ContainerContent = styled.div`
  .blog-card {
    height: 100%;
    & img {
      min-height: 50%;
    }
    .know-more {
      margin: 0;
    }
  }
`;
export const ContainerCategories = styled.div``;

export const ExternContainer = styled.div`
  padding-bottom: 50px;
`;
