import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const CourseShowContainer = styled.div`
  .info {
    background-color: transparent;
    padding: 30px 0;
  }
`;
export const ShowVideo = styled.div`
  background-color: red;
  height: 400px;
`;
export const Actions = styled.div`
  margin-top: 10px;
`;
export const SeeLater = styled.button`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &:disabled {
    cursor: not-allowed;
  }

  i {
    margin-left: 5px;
    font-size: 18px;
    :hover {
      font-weight: bold;
    }
  }
`;

export const Like = styled.div`
  i {
    color: #333;
    margin: 0 5px;
    font-size: 20px;
    :hover {
      font-weight: bold;
    }
    cursor: pointer;
  }
`;

export const EpisodeDescription = styled.div`
  padding: 20px 10px 10px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.06);
  margin: 20px 0;

  h4 {
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
  }

  p {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 10px;
    ont-family: Roboto, sans-serif;
    line-height: 20px;
    color: #333;
    word-break: break-word;
  }
`;

export const SupportMaterial = styled.div`
  padding: 20px 30px 40px;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-bottom: 20px;
  h1 {
    width: auto;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    margin: 0.67em 0;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: Roboto, sans-serif;
  }
`;

export const Material = styled.div`
  display: flex;
`;

export const Share = styled.div`
  p {
    font-size: 15px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #333;
  }
`;

export const By = styled(Link)`
  display: flex;
  text-decoration: none;
  width: fit-content;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  p {
    margin-left: 10px;
    color: #0b111e;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0;
  }
`;

export const SerieImage = styled.img`
  width: 100%;
`;
