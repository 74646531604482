import React from 'react';
import {CategorieItem} from '../styles';

const Item = ({content, className, paddingLeft = 0}) => {
  return (
    <>
      <CategorieItem className={className || ''} to={`/blog/${content.slug}`} style={{paddingLeft}}>
        {content.title}
      </CategorieItem>
      {content.children &&
        content.children.length > 0 &&
        content.children.map((children, index) => (
          <Item key={index} paddingLeft={paddingLeft + 20} content={children} />
        ))}
    </>
  );
};

export default Item;
