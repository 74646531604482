import React from 'react';
import StripTags from 'striptags';
import {Link} from 'react-router-dom';
import {priceFormat} from '../../utils/functions';

import {PlanCardContainer, Title, Type, Description, Price} from './styles';

const PlanCard = ({className = '', content, onSeleted}) => {
  const price = content.planItems.reduce(
    (accumulator, planItem) => accumulator + planItem.price,
    0,
  );
  return (
    <PlanCardContainer
      className={`d-flex flex-column align-items-center justify-content-center ${className}`}>
      <Title className="my-2">{content.name}</Title>
      <Type>Plano</Type>
      <Description
        className="px-1 px-md-2 px-lg-6 mt-5"
        dangerouslySetInnerHTML={{__html: StripTags(content.description)}}
      />

      <Price className="my-4">{priceFormat(price)}</Price>
      <Link className="btn mb-2" to="/checkout" onClick={() => onSeleted(content)}>
        Adquirir Plano
      </Link>
    </PlanCardContainer>
  );
};

export default PlanCard;
