import React from 'react';
import {Col, Row} from 'reactstrap';
import SocialItem from './_partials/SocialItem';
import TitleMenuFooter from '../TitleMenuFooter';

const RedesSociais = ({content, block}) => {
  return (
    <Col xs={6} className="d-flex justify-content-center justify-content-md-start p-0">
      <Row noGutters className="d-flex flex-column p-0">
        <TitleMenuFooter>{block.title}</TitleMenuFooter>
        {content &&
          content.menuItems.length &&
          content.menuItems.map((item, index) => (
            <SocialItem
              key={index}
              href={item.url}
              className={`${item.title} ${item.icon}`}
              alt={item.title}>
              {item.title}
            </SocialItem>
          ))}
      </Row>
    </Col>
  );
};

export default RedesSociais;
