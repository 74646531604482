import React from 'react';
import {Row, Col} from 'reactstrap';
import {Container, Wallpaper, Tag, Title, ExternContainer, ContainerWallpaper} from './styles';
import NoImagePost from '../../assets/images/noImagePost.jpeg';
import KnowMore from '../KnowMore';

const BlogCard = ({content}) => {
  const postImage =
    // eslint-disable-next-line no-nested-ternary
    content.image ? content.image.url : NoImagePost;
  return (
    <ExternContainer to={`/blog/${content.slug}/${content.id}`}>
      <Container className="blog-card">
        {postImage && (
          <ContainerWallpaper>
            <Wallpaper src={postImage} alt="wallpaper" />
          </ContainerWallpaper>
        )}
        <Row noGutters className="p-3 flex-grow-1">
          {content.category.length && content.category[0].title && (
            <Tag className="pb-1">{content.category[0].title}</Tag>
          )}

          <Col xs={12}>
            <Title>{content.title}</Title>
          </Col>
          <Col xs={12} className="d-flex align-items-end">
            <KnowMore
              style={{marginLeft: 0, marginTop: '8px'}}
              to={`/blog/${content.slug}/${content.id}`}
              target={content.target || ''}
              className="know-more"
            />
          </Col>
        </Row>
      </Container>
    </ExternContainer>
  );
};

export default BlogCard;
