import styled from 'styled-components';

export const HeaderContent = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-link img {
    max-width: 170px;
  }
`;

export const BurguerMenu = styled.div`
  font-size: 18px;
  color: #096ad0;
  margin-right: 20px;
  text-align: right;
  @media (min-width: 960px) {
    display: none;
  }
`;

export const Button = styled.button.attrs({
  type: 'button',
})``;

export const BtnClose = styled.i`
  width: 20px;
  height: 20px;
  background: #666e7a;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Navbar = styled.nav`
  position: initial;
  z-index: 1000;
  width: 100%;
  background-color: #0b111e;
  padding: 0;
  @media (min-width: 960px) {
    position: fixed;
    padding-left: 10px;
    padding-right: 30px;
  }
`;

export const NavMenu = styled.nav`
  .search-button {
    padding: 5px 0;
  }
  .ul-profile {
    display: block;
    left: 0;
    top: -10px;
    right: 0;
    min-width: max-content;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .profile-user {
    display: none;
  }
  background-color: #fff;
  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  @media (min-width: 960px) {
    .ul-profile {
      display: none;
    }
    .profile-user {
      display: inherit;
    }

    background-color: #0b111e;
    padding-right: 20px;
    > div {
      height: auto !important;
      overflow: inherit !important;
      > div {
        display: block !important;
      }
    }
  }
  > div > div > ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (min-width: 960px) {
      flex-direction: row;
      > li a,
      .a {
        color: #fff !important;
      }
    }
    > li {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      a,
      .a {
        background: none;
        border: none;
        position: relative;
        color: #0b111e;
        text-decoration: none;
        padding: 18px 20px;
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        text-transform: uppercase;
        display: flex;
        cursor: pointer;
        align-items: center;
        min-height: 40px;
        .icon-user {
          margin-right: 5px;
          position: relative;
          font-size: 14px;
        }

        > i {
          &.fa-angle-down {
            display: none;
          }
          @media (min-width: 960px) {
            &.fa-angle-down {
              display: inline-block;
            }
            margin-left: 8px;
          }
          font-size: 20px;
          line-height: 0.6;
        }
      }
      &:hover > ul {
        display: block;
      }
      ul {
        padding-left: 15px;
        @media (min-width: 960px) {
          padding-left: 0;
          display: none;
          position: absolute;
          top: 50px;
          width: 100%;
          min-width: 220px;
          background-color: #36b5ab;
          border-radius: 5px;
          box-shadow: 0 6px 6px rgba(102, 110, 122, 0.1);
        }
        li {
          a {
            color: #0b111e;
            text-transform: initial;
          }
        }
      }
    }
  }
`;
