/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import {ErrorMessage, Form, Formik} from 'formik';
import {Col, Row} from 'reactstrap';
import {mapInitialValues} from './util/mapInitialValues';
import {mapValidations} from './util/mapValidations';
import MakeField from './MakeField';
import {hasProperty} from '../../utils/functions';
import {ElementField, ElementLabel} from './styles';
import ComponentFactory from './ComponentFactory';

const GenerateForm = ({
  groupFields,
  onSubmit,
  settings,
  middleComponent: MiddleComponent,
  bottomComponents: BottomComponents,
}) => {
  const getSettings = (type) => {
    switch (type) {
      case 'button.text':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'text'))
          return settings.button.text;
        return 'Salvar';
      case 'button.textSubmitting':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'textSubmitting'))
          return settings.button.textSubmitting;
        return 'Salvando...';
      default:
        return '';
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mapInitialValues(groupFields)}
      validationSchema={mapValidations(groupFields)}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}>
      {({isSubmitting}) => (
        <Form>
          {groupFields.map((group, index) => (
            <div key={`group-${index}`} className="group-field" id={`group-${index}`}>
              {group.name && <label htmlFor={`group-${index}`}>{group.name}</label>}

              <Row>
                {group.fields.map((field, idx) =>
                  field.component ? (
                    <ComponentFactory content={field.component} />
                  ) : (
                    <Col
                      key={`field-${idx}`}
                      xs={hasProperty(field.columns) ? field.columns.xs : 12}
                      {...field.columns}>
                      {field.type === 'checkbox' ? (
                        <ElementField className="element-field d-flex align-items-center checkbox">
                          <MakeField {...field} />
                          <span />
                          {field.hasLabel && (
                            <ElementLabel className="label-title" htmlFor={field.name}>
                              {field.label}
                            </ElementLabel>
                          )}
                          <ErrorMessage component="span" name={field.name} />
                        </ElementField>
                      ) : field.type === 'title' ? (
                        <h2 className="title">{field.hasLabel && field.label}</h2>
                      ) : (
                        <ElementField className="element-field">
                          {field.hasLabel && (
                            <ElementLabel className="label-title" htmlFor={field.name}>
                              {field.label}
                            </ElementLabel>
                          )}
                          <MakeField {...field} />
                          <ErrorMessage component="span" name={field.name} />
                        </ElementField>
                      )}
                    </Col>
                  ),
                )}
              </Row>
            </div>
          ))}

          <Row>
            {BottomComponents ? <BottomComponents /> : <></>}
            <Col xs={12}>
              <div className="footer-buttons">
                <button type="submit" className="btn btn-primary">
                  {isSubmitting ? getSettings('button.textSubmitting') : getSettings('button.text')}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

GenerateForm.propTypes = {
  groupFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  settings: PropTypes.object,
};

export default GenerateForm;
