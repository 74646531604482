import React, {useEffect, useState, useRef} from 'react';
import StripTags from 'striptags';
import {Col, Row, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import {ContainerInfo, Title, Description, ExternContainer} from './styles';

const Info = ({content, className = ''}) => {
  const [animation, setAnimation] = useState(false);
  const element = useRef(null);

  const onScroll = () => {
    if (!element.current) {
      return;
    }
    const activeAnimation =
      element.current.offsetTop <=
      document.documentElement.scrollTop + document.documentElement.clientHeight - 100;

    if (activeAnimation) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  return (
    <ExternContainer ref={element} className={className}>
      <Container className={`p-0 ${animation ? 'opacity-animation' : ''}`}>
        <ContainerInfo className="d-flex flex-column align-items-center">
          <Title>{content.title}</Title>
          <Row className="d-flex flex-column align-items-center">
            <Col xs={10} md={7} className="d-flex flex-column align-items-center">
              <Description dangerouslySetInnerHTML={{__html: StripTags(content.description)}} />
              <Link to={content.link}>
                <button type="button" className="btn">
                  {content.title}
                </button>
              </Link>
            </Col>
          </Row>
        </ContainerInfo>
      </Container>
    </ExternContainer>
  );
};

export default Info;
