import {passToPascalCase} from './manipulateString';

export const hasProperty = (source, param) => {
  if (!source) return false;
  return Object.prototype.hasOwnProperty.call(source, param);
};

export const priceFormat = (number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
};

export const onlyNumbers = (value) => {
  return value.replace(/\D/g, ``);
};

export const validCard = (value) => {
  const split = value.split('/');
  const month = split[0];
  const year = split[1];
  const yearNow = new Date().getUTCFullYear() - 2000;

  if (parseInt(year, 10) > yearNow) {
    return true;
  }
  if (parseInt(year, 10) === yearNow) {
    const monthNow = new Date().getUTCMonth() + 1;
    if (parseInt(month, 10) >= monthNow) {
      return true;
    }
  }
  return false;
};

export const trans = (val) => {
  const translates = [
    {cpf: 'CPF'},
    {phone: 'Telefone'},
    {installments: 'Parcelas'},
    {'the field:': 'O campo:'},
    {payment_method_id: 'Tipo de pagamento'},
    {holder_name: 'Nome (como no cartão)'},
    {card_number: 'Número do cartão'},
    {card_expiration: 'Data de Validade'},
    {card_cvv: 'CVV'},
    {'select a plan': 'Necessário selecionar um plano'},
    {
      'Subscription successfully completed': 'Assinatura realizada com sucesso',
    },
    {daily: 'Diário'},
    {monthly: 'Mensal'},
    {weekly: 'Semanal'},
    {yearly: 'Anual'},
    {active: 'Ativo'},
    {inactive: 'Inativo'},
    {deleted: 'Deletado'},
    {future: 'Trial'},
    {expired: 'Expirado'},
    {review: 'Em análise'},
    {pending: 'Pendente'},
    {paid: 'Pago'},
    {canceled: 'Cancelado'},
    {beginning_of_period: 'Início do período'},
    {end_of_period: 'Fim do período'},
    {day_of_month: 'Dia do mês'},
    {scheduled: 'Agendado'},
    {percentage: 'Percentagem'},
    {amount: 'Montante'},
    {quantity: 'Quantidade'},
    {days: 'Dias'},
    {months: 'Meses'},
    {created: 'Criado'},
    {'profile.update.success': 'Usuário atualizado com sucesso.'},
    {'profile.identity': 'CPF'},
    {'profile.zipCode': 'CEP'},
    {'profile.street': 'Logradoro'},
    {'profile.houseNumber': 'Número'},
    {'profile.complement': 'Completo'},
    {'profile.neighborhood': 'Bairro'},
    {'profile.city': 'Cidade'},
    {'profile.state': 'Estado'},
    {'profile.country': 'País'},
    {'profile.phoneNumber': 'Telefone'},
    {'profile.website': 'Site'},
    {'profile.company': 'Empresa'},
    {'profile.companyIdentity': 'CNPJ'},
    {'profile.companyJobRole': 'Cargo'},
    {'profile.name': 'Nome'},
    {'profile.email': 'Email'},
    {'profile.plainPassword': 'Senha'},
    {
      'plans.noplan': 'Você não possui um plano ativo. Que tal assinar agora mesmo um plano?',
    },
    {'business.send.success': 'Orçamento enviado com sucesso.'},
    {'business.description': 'Descrição'},
    {'business.userQuantity': 'Quantidade de usuários'},
    {'business.plan': 'Plano'},
    {
      'change.payment.method.server.error': 'Ocorreu um erro ao conectar ao servidor.',
    },
    {'change.payment.method.error': 'Erro ao alterar os dados de pagamento.'},
    {
      'change.payment.method.success': 'Dados de pagamento alterados com sucesso.',
    },
    {'comment.form.success': 'Comentário enviado com sucesso.'},
    {'comment.form.error': 'Ocorreu um erro ao enviar o comentário.'},
    {'business.user.create.success': 'Associado criado com sucesso.'},
    {'business.user.update.success': 'Associado alterado com sucesso.'},
    {'business.user.delete.success': 'Associado excluído com sucesso.'},
    {
      'business.user.password.success': 'Senha do associado alterada com sucesso.',
    },
    {'business.user.error': 'Ocorreu um erro em nosso sistema.'},
    {'business.user.create': 'Criar usuário.'},
    {'business.user.update': 'Alterar usuário.'},
    {'business.user.delete': 'Excluir usuário.'},
    {'business.user.password': 'Alterar senha.'},
  ];

  const result = translates.filter((translate) =>
    // eslint-disable-next-line no-prototype-builtins
    translate.hasOwnProperty(val) ? translate[val] : null,
  );

  return result[0] ? result[0][val] : val;
};

// filtra as categorias
export const filterCategories = (array) => {
  const arrayAux = [];

  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; ; j++) {
      if (j >= array.length) {
        j = 0;
      }
      if (
        array[j].children.length > 0 &&
        array[j].children.filter((aux) => aux['@id'] === array[i]['@id']).length > 0
      ) {
        break;
      }

      if (j === i) {
        arrayAux.push(array[i]);
        break;
      }
    }
  }

  return arrayAux;
};

// filtra as categorias de autores
export const filterAuthors = (array = []) => {
  return array.map((author) => {
    return {
      title: author.name,
      slug: `?autor=${author.name.replaceAll(' ', '')}`,
    };
  });
};

export const filtrarEpisodes = (array = []) => {
  return array.map((episode) => ({
    slug: episode.serie.slug,
    id: episode.serie.id,
    title: episode.title,
    image: episode.image,
    tags: [],
    hasFreeEpisodes: true,
  }));
};