import React, {createContext, useContext, useState} from 'react';

export const AnamneseContext = createContext({});

export default function PageProvider({children}) {
  const [selectedPlan, setSelectedPlan] = useState();
  const [plans, setPlans] = useState([]);
  return (
    <AnamneseContext.Provider
      value={{
        selectedPlan,
        setSelectedPlan,
        plans,
        setPlans,
      }}>
      {children}
    </AnamneseContext.Provider>
  );
}

// Define hooks
export function useAnamnese() {
  const context = useContext(AnamneseContext);
  if (!context) throw new Error('useAnamnese must be used within a AnamneseProvider');
  const {selectedPlan, setSelectedPlan, plans, setPlans} = context;

  return {selectedPlan, setSelectedPlan, plans, setPlans};
}
