import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 5px 0;
`;

export const Title = styled.span`
  font-family: Roboto, sans-serif;
  color: #333;
  margin-right: 8px;
`;

export const Percentage = styled.strong`
  font-family: Roboto, sans-serif;
  color: #333;
  margin-right: 8px;
`;

export const Bar = styled.div`
  height: 7px;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #000000;
`;

export const PercentageBar = styled.div`
  background-color: #36b5ab;
  height: 100%;
`;
