export const settings = {
  titleSite: 'Meu Shape',
  perPage: 12,
  itemsCarousel: 4,
  copyright: '© MEU SHAPE.COM 2020 Todos os direitos reservados.',
  pageBase: 'list-all-pages',
  anamnesePage: 'anamnese-page',
  home: 'home',
  homeLogada: 'home-logada',
  enableImageBuild: false,
  pagination: {
    pageRangeDisplayed: 3,
    hideDisabled: true,
    hideFirstLastPages: false,
    hideNavigation: true,
    linkClassFirst: 'arrow first',
    linkClassPrev: 'arrow prev',
    linkClassNext: 'arrow next',
    linkClassLast: 'arrow last',
  },
};
