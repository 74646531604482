import React from "react";
import { Image, Name, Container } from "./styles";

const By = ({ name, image }) => {
  return (
    <Container>
      <Image
        src={
          image ||
          `https://www.gelderpop.nl/uploads/images/artiesten_2019/tba.jpg`
        }
      ></Image>
      <Name>Por: {name || "Fabrício"}</Name>
    </Container>
  );
};

export default By;
