import api from '../../api';

const getSeries = (params = {}) =>
  api.get('/series', {
    params: {
      ...params,
    },
  });
const getCategories = (params = {}) =>
  api.get('/categories', {
    params: {
      ...params,
    },
  });

const getEpisodes = (params = {}) =>
  api.get('/episodes', {
    params: {
      ...params,
    },
  });

const getSerie = (id, params = {}) =>
  api.get(`/series/${id}/content`, {
    params: {
      ...params,
    },
  });
const getSerieFree = (id, params = {}) =>
  api.get(`/series/${id}/content/free`, {
    params: {
      ...params,
    },
  });

const getEpisode = (id, params = {}) =>
  api.get(`episode/${id}`, {
    params: {
      ...params,
    },
  });

const setLike = (serieId) =>
  api.post('/user_collection_likes', {
    // episode: `/v1/episodes/${episodeId}`,
    serie: `/v1/series/${serieId}`,
  });

const getLikes = () => api.get('/user_collection_likes');

const setViewProgress = (data) => api.post('/user_collection_views', data);
const getSeriesInProgress = () => api.get('/user_collection_views');

const setSeeLater = (data) => api.post('/user_collection_watch_laters', data);

const seeLaterSeries = () => api.get(`/user_collection_watch_laters`);

export {
  getSeries,
  getEpisodes,
  getSerie,
  getEpisode,
  setLike,
  setViewProgress,
  getSerieFree,
  getCategories,
  setSeeLater,
  seeLaterSeries,
  getLikes,
  getSeriesInProgress,
};
