import axios from 'axios';
import jwt from 'jwt-decode';
import {getToken, refreshToken} from '../utils/auth';
import {checkDetails} from '../utils/checkDetails';
import {hasProperty} from '../utils/functions';

const apiUrl = process.env.REACT_APP_API_URL;
const {CancelToken} = axios;
let cancel;

const params = getToken()
  ? {
      baseURL: apiUrl,
      headers: {Authorization: getToken()},
    }
  : {
      baseURL: apiUrl,
    };

const api = axios.create(params);

api.interceptors.request.use(
  async (config) => {
    if (getToken()) {
      const decode = jwt(getToken());
      const currentTime = new Date().getTime() / 1000;
      if (currentTime >= decode.exp) {
        const newToken = refreshToken(decode.aud);
        if (newToken) {
          Object.assign(config, {
            ...config,
            headers: {
              ...config.headers,
              Authorization: newToken,
            },
          });
        }
      }
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    if (cancel) {
      cancel(); // cancel request
    }

    response.cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    return response;
  },
  (error) => {
    try {
      const status = hasProperty(error, 'response') ? error.response.status : error;

      switch (status) {
        case 401:
          checkDetails(error.response.data.detail);
          break;
        case 403:
          if (error.response.data['hydra:description']) {
            checkDetails(error.response.data['hydra:description']);
          } else {
            checkDetails('');
          }
          break;
        case 404:
          if (error.response.config.url.indexOf('/pages/slug') !== -1) {
            window.location = `${window.location.origin}/404`;
          }
          console.error(`Error ${status}`);
          break;
        case 405:
        case 406:
        case 422:
        default:
          console.error(`Error ${status}`);
      }
    } catch (e) {
      window.location = `${window.location.origin}/erros`;
    }
    return Promise.reject(error);
  },
);

export default api;
