/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {Container, Row, Col} from 'reactstrap';
import axios from 'axios';
import {useAlert} from 'react-alert';
import StripTags from 'striptags';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import {getToken} from '../../utils/auth';
import {settings} from '../../config/settings';
import {
  getSerie,
  setLike,
  getSerieFree,
  setSeeLater,
} from '../../services/endpoints/courses/courses';
import {getFeature} from '../../services/endpoints/general/settings';
import Title from '../../components/Title';
import {
  CourseShowContainer,
  Actions,
  SeeLater,
  Like,
  EpisodeDescription,
  SupportMaterial,
  Material,
  By,
  SerieImage,
} from './styles';

import SambaVideoPlaylist from './_partials/SambaVideoPlaylist';
import Share from '../../components/Share';
import Info from '../../components/Info';
import TotalProgress from '../../components/TotalProgress';
import Attachments from './_partials/Attachments';
import Playlist from './_partials/Playlist';

const CourseShow = () => {
  const [infoBanner, setInfoBanner] = useState();
  const [loading, setLoading] = useState(true);
  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();
  const [serie, setSerie] = useState();
  const [currentEpisode, setCurrentEpisode] = useState();
  const [seeLaterState, setSeeLaterState] = useState();
  const [loadingSeeLater, setLoadingSeeLater] = useState(false);
  const [interaction, setInteraction] = useState(0);
  const {slug = ''} = useParams();
  const logged = !!getToken();

  const alert = useAlert();

  setPageBase(settings.pageBase);
  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEpisodeSlideClick = (data) => {
    setCurrentEpisode(data);
  };

  const favoriteHandler = async () => {
    try {
      if (interaction) {
        setInteraction(false);
      } else {
        setInteraction(true);
      }
      await setLike(serie.id);
    } catch (error) {
      console.error('Course.Show', error);
    }
  };

  // load serie content
  useEffect(() => {
    setLoading(true);
    let arrEp;

    if (logged) {
      getSerie(slug)
        .then((response) => {
          arrEp = response.data.serie.episodes;

          setSerie(response.data.serie);

          if (response.data.serie.currentUserCollectionSerie.like) {
            setInteraction(true);
          } else {
            setInteraction(false);
          }

          if (arrEp.length && arrEp[0]) {
            setCurrentEpisode(arrEp[0]);
          }

          if (response.data.serie.currentUserCollectionSerie) {
            setSeeLaterState(response.data.serie.currentUserCollectionSerie.watchLater);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Course.show', error);
          setLoading(false);
        });
    } else {
      axios
        .all([getSerieFree(slug), getFeature(1)])
        .then((response) => {
          arrEp = response[0].data.serie.episodes.filter((ep) => ep.free) || [];

          setSerie(response[0].data.serie);
          if (arrEp.length && arrEp[0]) {
            setCurrentEpisode(arrEp[0]);
          }
          setInfoBanner(response[1].data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Course.show', error);
          setLoading(false);
        });
    }
    return () => {};
  }, []);

  const handleSeeLater = async () => {
    setLoadingSeeLater(true);
    await setSeeLater({
      serie: serie['@id'],
    });

    if (seeLaterState) {
      setSeeLaterState(false);
      alert.show('Removido com sucesso de assistir mais tarde', {
        closeCopy: 'Ok',
      });
    } else {
      setSeeLaterState(true);
      alert.show('Inserido com sucesso em assistir mais tarde', {
        closeCopy: 'Ok',
      });
    }
    setLoadingSeeLater(false);
  };

  return (
    <CourseShowContainer className="pb-5">
      <Container className="px-3 px-lg-0">
        {!loading ? (
          <>
            <Helmet>
              <title>{`${settings.titleSite}`}</title>
            </Helmet>
            {serie ? (
              !currentEpisode ? (
                <>
                  <Title className="title">{serie.title}</Title>
                  {logged ? (
                    <>
                      {serie.image && <SerieImage src={serie.image.url} />}
                      <h2 style={{color: '#333'}}>Este curso ainda não possui videos</h2>
                    </>
                  ) : (
                    <>
                      {serie.image && <SerieImage src={serie.image.url} />}
                      <h3 className="my-3" style={{color: '#333'}}>
                        Este curso não possui videos gratuitos
                      </h3>
                    </>
                  )}
                  {serie.synopsis && (
                    <EpisodeDescription>
                      <h4>Descrição do curso</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: StripTags(serie.synopsis),
                        }}
                      />
                      <By to={`/professores/${serie.instructor.slug}/${serie.instructor.id}`}>
                        <img src={serie.instructor.avatar.url} alt="Instructor" />
                        <p>Por: {serie.instructor.name}</p>
                      </By>
                    </EpisodeDescription>
                  )}
                </>
              ) : (
                <>
                  <Helmet>
                    <title>{`${settings.titleSite}: ${serie.title}`}</title>
                  </Helmet>
                  <Title className="title">{currentEpisode.title}</Title>
                  <Row noGutters>
                    {serie.currentUserCollectionSerie && (
                      <Col xs={12} md={5} lg={3}>
                        <TotalProgress
                          progress={serie.currentUserCollectionSerie.view.percentage}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row noGutters>
                    <Col xs={12} md={serie.episodes.length > 1 ? 8 : 12} className="pr-0 pr-md-4">
                      <SambaVideoPlaylist currentEpisode={currentEpisode} currentSerie={serie} />
                    </Col>
                    {serie.episodes.length > 1 && (
                      <Col xs={12} md={4}>
                        <Playlist
                          collection={serie.episodes}
                          onPlaylistClick={handleEpisodeSlideClick}
                          currentEpisode={currentEpisode}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row noGutters>
                    <Col xs={12} md={serie.episodes.length > 1 ? 8 : 12} className="pr-0 pr-md-3">
                      {logged && (
                        <Actions className="d-flex justify-content-between px-2">
                          {seeLaterState ? (
                            <SeeLater
                              onClick={handleSeeLater}
                              disabled={loadingSeeLater}
                              title="Remover curso de Assistir Mais Tarde">
                              Remover de assistir mais tarde <i className="far fa-clock" />
                            </SeeLater>
                          ) : (
                            <SeeLater
                              onClick={handleSeeLater}
                              disabled={loadingSeeLater}
                              title="Inserir curso em Assistir Mais Tarde">
                              Assistir mais tarde <i className="far fa-clock" />
                            </SeeLater>
                          )}
                          <Like>
                            <i
                              className="far fa-thumbs-up"
                              style={interaction ? {fontWeight: 'bold'} : {}}
                              onClick={() => favoriteHandler()}
                            />
                          </Like>
                        </Actions>
                      )}
                      <EpisodeDescription>
                        <h4>Descrição do vídeo</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: StripTags(
                              (currentEpisode && currentEpisode.synopsis) || serie.synopsis,
                            ),
                          }}
                        />
                        <By to={`/professores/${serie.instructor.slug}/${serie.instructor.id}`}>
                          <img src={serie.instructor.avatar.url} alt="Instructor" />
                          <p>Por: {serie.instructor.name}</p>
                        </By>
                      </EpisodeDescription>
                      {logged && currentEpisode && currentEpisode.attachments.length > 0 && (
                        <SupportMaterial>
                          <h1>Material de apoio</h1>
                          <Material>
                            {currentEpisode.attachments.length > 0 &&
                              currentEpisode.attachments.map((item, index) => (
                                <Col xs={12} md={4} key={index}>
                                  <Attachments attachment={item} />
                                </Col>
                              ))}
                          </Material>
                        </SupportMaterial>
                      )}
                      <Share
                        title={serie.title}
                        url={window.location.href}
                        media={serie.image ? serie.image.url : ''}
                      />
                    </Col>
                  </Row>
                  {infoBanner && <Info className="info" content={infoBanner} />}
                </>
              )
            ) : (
              <h2>Erro ao carregar página</h2>
            )}
          </>
        ) : (
          <>
            <Helmet>
              <title>{`${settings.titleSite}`}</title>
            </Helmet>
            <Col xs={12} className="d-flex justify-content-center my-5">
              <i className="far fa-spinner-third loading" />
            </Col>
          </>
        )}
      </Container>
    </CourseShowContainer>
  );
};

export default CourseShow;
