import React, {useState} from 'react';
import Script from 'react-load-script';
import axios from 'axios';

const MobileWebView = ({match}) => {
  const [init, setInit] = useState(false);
  let flag = false;
  const api_url = process.env.REACT_APP_API_URL;
  const {reference, content, playerKey, progress, token} = match.params;

  const initSambaPlayer = () => {
    setInit(true);
  };

  const eventListener = (player) => {
    const eventParam = parseInt(player.eventParam, 10);
    switch (player.event) {
      case 'onLoad':
        break;
      case 'onStart':
        break;
      case 'onListen':
        if (!flag && eventParam > 1 && eventParam % 15 === 0) {
          axios.post(
            `${api_url}/user_collection_views`,
            {
              progress: player.eventParam,
              percent: 0,
              episode: `/v1/episodes/${content}`,
            },
            {
              headers: {Authorization: token},
            },
          );
          flag = true;
        } else if (flag && eventParam > 1 && eventParam % 16 === 0) {
          flag = false;
        }
        break;
      case 'onPause':
        break;
      case 'onResume':
        break;
      case 'onSeek':
        break;
      case 'onCuepoint':
        break;
      case 'onMediaView':
        break;
      case 'onProgress':
        break;
      case 'onFinish':
        axios.post(
          `${api_url}/user_collection_views`,
          {
            progress: player.duration,
            percent: 100,
            episode: `/v1/episodes/${content}`,
          },
          {
            headers: {Authorization: token},
          },
        );
        break;
      case 'onError':
        break;
      default:
    }
  };

  if (init) {
    (() =>
      new window.SambaPlayer('player', {
        ph: playerKey,
        m: reference,
        playerParams: {
          enabledShare: false,
          resume: progress,
          enableControls: ['settings', 'fullscreen', 'play', 'time', 'volume'],
        },
        events: {
          '*': eventListener,
        },
      }))();
  }

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100vh',
      }}>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={initSambaPlayer}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          width: '100%',
        }}
        id="player"
      />
    </div>
  );
};

export default MobileWebView;
