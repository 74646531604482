import styled from 'styled-components';

export const CourseContainer = styled.div`
  width: 100%;
  .row {
    height: 100%;
  }
`;

export const ExternContainer = styled.div`
  background-color: #0b111e;
  padding: 60px 0;
`;

export const ContainerGrid = styled.div`
  display: grid;
  gap: 10px;
`;
