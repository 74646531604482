import styled from 'styled-components';

export const Image = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

export const Name = styled.p`
  font-size: 13px;
  margin: 0;
  color: white;
  line-height: 14px;
  font-weight: 400;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
