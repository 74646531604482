import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Container, Row, Col} from 'reactstrap';
import DefaultMenuFooter from '../DefaultMenuFooter';
import Copyright from '../Copyright';
import {getEndpoints} from '../../services/endpoints/getEndpoints';
import {getPage} from '../../services/endpoints/cms/pages';
import {
  Box,
  ContentPage,
  ContentShow,
  Footer,
  Header,
  HeaderContent,
  InnerMain,
  Main,
  ContainerFooter,
} from './styled';
import Banner from '../Banner';
import LoadBlocks from './_partials/LoadBlocks';
import {hasProperty} from '../../utils/functions';
import {usePageBase, useCurrentPage} from '../../contexts/PageContext';
import {settings} from '../../config/settings';
import Title from '../Title';

const Base = ({children}) => {
  const {pageBase} = usePageBase();
  const {currentPage} = useCurrentPage();

  const [errorDefaultPage, setErrorDefaultPage] = useState(false);
  const [page, setPage] = useState({});
  const [containersDefault, setContainersDefault] = useState([]);
  const [containers, setContainers] = useState([]);

  const refactorBlock = async (block) => {
    const blockContent = await getEndpoints(block.block.endpoint);
    const componentName = block.block.component ? block.block.component.name : '';
    const {container} = block;
    return {
      position: block.position || 0,
      container: container.name || null,
      componentName,
      content: blockContent.data || null,
      block: {
        id: block.block.id,
        title: block.block.title || '',
        description: block.block.description || null,
      },
    };
  };

  useEffect(() => {
    setPage({});
    setContainersDefault([]);
    setContainers([]);
    if (pageBase) {
      (async () => {
        try {
          const response = await getPage(pageBase);
          response.data.blockPages.forEach(async (block) => {
            const content = await refactorBlock(block);
            setContainersDefault((prevValue) => [...prevValue, content]);
          });
        } catch (err) {
          if (err.response && err.response.status) {
            setErrorDefaultPage(true);
          }
          console.error('Base.useEffect.defaultBlocks', err);
        }
      })();
    }
  }, [pageBase]);

  useEffect(() => {
    setPage({});
    setContainers([]);
    if (currentPage) {
      (async () => {
        try {
          const response = await getPage(currentPage);
          setPage(response.data || {});
          response.data.blockPages.forEach(async (block) => {
            const content = await refactorBlock(block);
            setContainers((prevValue) => [...prevValue, content]);
          });
        } catch (err) {
          console.error('Base.useEffect.slug', err);
        }
      })();
    }
  }, [currentPage]);

  if (errorDefaultPage) {
    return (
      <p>
        Você precisa criar uma página
        <span
          style={{
            backgroundColor: '#ffedc6',
            padding: '0 5px',
            fontSize: 12,
            color: '#900',
            margin: '0 5px',
            fontStyle: 'italic',
          }}>
          {settings.pageBase}
        </span>
        para funcionar
      </p>
    );
  }

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {settings.titleSite}
          {hasProperty(page, 'hideTitle') && !page.hideTitle ? `: ${page.title}` : ''}
        </title>
      </Helmet>

      <Header>
        <HeaderContent>
          <LoadBlocks containers={[...containersDefault, ...containers]} locale="header" />
        </HeaderContent>
      </Header>

      {page && page.banner && <Banner content={page.banner} />}

      <Main>
        <LoadBlocks containers={[...containersDefault, ...containers]} locale="main" />
      </Main>

      <InnerMain>
        {Object.keys(page).length > 0 && (
          <Container className="px-2 px-lg-0">
            <ContentShow>
              {!page.hideTitle && <Title className="title">{page.title}</Title>}

              <LoadBlocks
                containers={[...containersDefault, ...containers]}
                locale="innerContent"
              />

              {hasProperty(page, 'content') && (
                <ContentPage dangerouslySetInnerHTML={{__html: page.content}} />
              )}
            </ContentShow>
          </Container>
        )}
        {children && <ContentPage>{children}</ContentPage>}
      </InnerMain>

      <LoadBlocks containers={[...containersDefault, ...containers]} locale="content" />

      <Footer>
        <Container className="d-flex flex-column">
          <ContainerFooter>
            <Row noGutters className="my-5">
              <Col xs={12} md={6}>
                <Row noGutters>
                  <LoadBlocks containers={[...containersDefault, ...containers]} locale="footer" />
                </Row>
              </Col>
              <Col xs={12} md={6} className="mt-5 mt-md-0">
                <DefaultMenuFooter />
              </Col>
            </Row>

            <Copyright />
          </ContainerFooter>
          <div className="line " />
        </Container>
      </Footer>
    </Box>
  );
};

export default Base;
