import styled from 'styled-components';

export const ExternContainer = styled.div`
  background-color: #fff;
  padding-bottom: 50px;
  .title {
    color: #0b111e;
  }
  .share {
    margin-top: 40px;
    margin-bottom: 15px;
  }
`;

export const Category = styled.p`
  margin-bottom: 0px;
  font-family: Roboto, sans-serif;
  color: #36b5ab;
  text-align: left;
  text-transform: uppercase;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 14px;
  line-height: 20px;
`;

export const Banner = styled.img`
  width: 100%;
`;
export const TitlePost = styled.h1`
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
  font-size: 38px;
  color: #333333;
`;

export const Description = styled.div`
  border-bottom: 1px solid #ebedee;

  h1 {
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
    font-size: 38px;
    color: #333333;
  }

  p {
    margin-bottom: 25px;
    color: rgba(24, 24, 29, 0.7);
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;
  }
  h4 {
    color: #333;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }
`;

export const Instructor = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  div {
    margin-left: 10px;
  }

  p,
  a {
    margin-top: 2px;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 28px;
    color: #333;
    text-decoration: none;
  }
  a {
    margin-left: 5px;
  }
  span {
    display: block;
    margin-bottom: 10px;
    opacity: 0.7;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #333;
  }
`;