import React from 'react';
import {Container, Title, InternContainer, CategorieName, StyledHr} from './styles';
import Item from './_partials/Item';

const Categories = ({content}) => {
  return (
    <Container className="mb-3 d-flex flex-column">
      <CategorieName>Categorias</CategorieName>
      {content &&
        content.length > 0 &&
        content.map((category, index) => (
          <InternContainer key={index} className="d-flex flex-column pb-3 mb-4">
            <Title to={`/cursos/${category.slug || ''}`} category={category.id}>
              <button type="button" className="btn">
                {category.title}
              </button>
            </Title>
            {category.children &&
              category.children.length > 0 &&
              category.children.map((item, indexIntern) => (
                <Item className="categorie-item" key={indexIntern} content={item} />
              ))}
            {category.children && category.children.length > 0 && <StyledHr />}
          </InternContainer>
        ))}
    </Container>
  );
};

export default Categories;
