import React from 'react';
import {Title} from './styles';

const BlockTitle = ({children, className, style}) => {
  return (
    <>
      <Title className={`${className || ''}`} style={style}>
        {children}
      </Title>
    </>
  );
};

export default BlockTitle;
