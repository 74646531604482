import styled from 'styled-components';

export const PlanCardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px 10px;

  .btn {
    line-height: 20px;
    :hover {
      background-color: #2d958d !important;
    }
  }
`;

export const Title = styled.h2`
  font-size: 26px;
  line-height: 67px;
  letter-spacing: 8px;
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
`;
export const Type = styled.span`
  color: #080110;
  font-weight: 400;
  font-size: 14px;
`;
export const Description = styled.p`
  color: #080110;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
`;
export const Price = styled.span`
  color: #080110;
  font-size: 48px;
  font-weight: 700;
`;
