import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Row, Col, Container} from 'reactstrap';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {getInstructor, getInstructorSeries} from '../../services/endpoints/instructor';
import Title from '../../components/Title';
import {settings} from '../../config/settings';
import {usePageBase, useCurrentPage} from '../../contexts/PageContext';
import CourseCard from '../../components/CourseCard';
import Banner from '../../components/Banner';
import {Container as ContainerTeacher, Summary, Courses} from './styles';

const TeacherInternPage = () => {
  const [teacher, setTeacher] = useState();
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const {id, slug} = useParams();

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(settings.pageBase);
  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // get teacher
    const parameters = {
      perPage: 3,
      page: 1,
      instructor: id,
    };
    setLoading(true);

    axios
      .all([getInstructor(id), getInstructorSeries(parameters)])
      .then(
        axios.spread((...responses) => {
          const teacherResponse = responses[0];
          const episodesResponse = responses[1];
          setTeacher(teacherResponse.data);
          setEpisodes(episodesResponse.data['hydra:member']);
          setLoading(false);
        }),
      )
      .catch((error) => {
        console.error('InternPage.Instructor', error);
        setLoading(false);
      });
  }, []);

  return (
    <ContainerTeacher>
      {!loading ? (
        <>
          <Helmet>
            <title>{`${settings.titleSite}: ${slug}`}</title>
          </Helmet>
          {teacher.banner && teacher.banner.images.length > 0 && (
            <Banner content={teacher.banner} noMetaInfo />
          )}

          <Container className="pl-4 pl-lg-0 mb-3">
            <Title className="page-title">SOBRE O PROFESSOR</Title>
            {teacher.curriculum ? (
              <Summary dangerouslySetInnerHTML={{__html: teacher.curriculum}} />
            ) : (
              <h3>Instrutor sem currículo até o momento</h3>
            )}

            {episodes.length > 0 && (
              <Courses className="mt-5">
                <Title className="page-title">Cursos ministrados pelo professor</Title>
                <Row noGutters>
                  {episodes.map((course, index) => (
                    <Col xs={12} md={4} key={index} className="pr-3 mb-2">
                      <CourseCard content={course} />
                    </Col>
                  ))}
                </Row>
              </Courses>
            )}
          </Container>
        </>
      ) : (
        <Container className="d-flex justify-content-center">
          <i className="far fa-spinner-third loading" />
        </Container>
      )}
    </ContainerTeacher>
  );
};

export default TeacherInternPage;
