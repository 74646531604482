import React from 'react';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
} from 'react-share';
import {Container, BackgroundButton} from './styles';

const Share = ({
  url = '',
  title = '',
  media = 'https://assets.website-files.com/5ebab15f00de7426d732dd15/5ed663aadb7bbe25a6681976_logo%20oficial-05-19.png',
  className = '',
}) => {
  return (
    <Container className={className}>
      <p>Compartilhe</p>
      <BackgroundButton style={{backgroundColor: '#0077b5'}}>
        <LinkedinShareButton url={url}>
          <i className="fab fa-linkedin-in" />
        </LinkedinShareButton>
      </BackgroundButton>
      <BackgroundButton style={{backgroundColor: '#3b5998'}}>
        <FacebookShareButton url={url} quote={title}>
          <i className="fab fa-facebook-f" />
        </FacebookShareButton>
      </BackgroundButton>
      <BackgroundButton style={{backgroundColor: '#1da1f2'}}>
        <TwitterShareButton url={url} title={title}>
          <i className="fab fa-twitter" />
        </TwitterShareButton>
      </BackgroundButton>
      <BackgroundButton style={{backgroundColor: '#bd081c'}}>
        <PinterestShareButton url={url} media={media}>
          <i className="fab fa-pinterest" />
        </PinterestShareButton>
      </BackgroundButton>
      <BackgroundButton style={{backgroundColor: '#25d366'}}>
        <WhatsappShareButton url={url} title={title} separator=":: ">
          <i className="fab fa-whatsapp" />
        </WhatsappShareButton>
      </BackgroundButton>
    </Container>
  );
};

export default Share;
