import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const KnowMore = styled(Link)`
  display: flex;
  color: #36b5ab !important;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 14px;
  line-height: 24px;
  margin: 10px;
  margin-right: 0px;
  align-items: center;

  & i {
    margin: 0px 15px;
    font-size: 16px;
    color: white;
    transition: 0.3s;
  }

  :hover {
    i {
      transform: translate(7px, 0px);
    }
  }
`;
