import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useAlert} from 'react-alert';
import ShowMyEvolution from './ShowMyEvolution';
import EvolutionImage from '../../../../components/EvolutionImage';
import {getDate} from '../../../../utils/manipulateDates';
import {InternTitle, ActualData, FormStyledEvolution, Field} from '../../styled';
import {setNewEvolution} from '../../../../services/endpoints/general/currentUser';

const MyEvolution = () => {
  const alert = useAlert();
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [rightImage, setRightImage] = useState();
  const [leftImage, setLeftImage] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [reloadAndClear, setReload] = useState(false);

  const verificationOk = () => {
    if (!weight) {
      alert.show('O campo Peso atual é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }
    if (!height) {
      alert.show('O campo Altura atual é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }
    if (!frontImage) {
      alert.show('O campo Foto Frente é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }
    if (!leftImage) {
      alert.show('O campo Foto Lado Esquerdo é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }
    if (!rightImage) {
      alert.show('O campo Foto Lado Direito é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }
    if (!backImage) {
      alert.show('O campo Foto Costas é obrigatório', {
        closeCopy: 'Ok',
      });
      return false;
    }

    return true;
  };

  const reload = () => {
    setReload(new Date());
    setHeight('');
    setWeight('');
  };

  const onSubmit = async () => {
    if (!verificationOk()) {
      // eslint-disable-next-line no-useless-return
      return;
    }

    try {
      setSubmiting(true);
      const front = new File([frontImage], `${new Date().getTime()}-front.jpg`, {
        type: 'image/jpeg',
      });
      const back = new File([backImage], `${new Date().getTime()}-back.jpg`, {
        type: 'image/jpeg',
      });
      const right = new File([rightImage], `${new Date().getTime()}-right.jpg`, {
        type: 'image/jpeg',
      });
      const left = new File([leftImage], `${new Date().getTime()}-left.jpg`, {
        type: 'image/jpeg',
      });

      const data = new FormData();
      data.append('frontPhoto', front);
      data.append('backPhoto', back);
      data.append('rightSidePhoto', right);
      data.append('leftSidePhoto', left);
      data.append('weight', weight);
      data.append('height', height);
      await setNewEvolution(data);
      setSubmiting(false);
      alert.show('Evolução atualizada com sucesso.', {
        closeCopy: 'Ok',
        onClose: reload,
      });
    } catch (e) {
      setSubmiting(false);
      console.error('Error uploadAvatarData:', e);
    }
  };

  return (
    <Row noGutters>
      <Col xs={6} className="p-0">
        <InternTitle>Minha evolução</InternTitle>
      </Col>
      <Col xs={6} className="p-0 d-flex justify-content-end">
        <ActualData>Data: {getDate(new Date())}</ActualData>
      </Col>
      <Col xs={12} className="p-0">
        <Row noGutters>
          <FormStyledEvolution>
            <Col xs={12} className="mt-3">
              <Row>
                <Col xs={12} md={6} className="p-0 pr-md-2">
                  <Field>
                    <InternTitle>Peso Atual</InternTitle>
                    <input
                      type="number"
                      onChange={(e) => setWeight(e.target.value)}
                      value={weight}
                      placeholder="Peso em quilogramas"
                    />
                  </Field>
                </Col>
                <Col xs={12} md={6} className="p-0 pl-md-2">
                  <Field>
                    <InternTitle>Altura atual</InternTitle>
                    <input
                      type="number"
                      onChange={(e) => setHeight(e.target.value)}
                      value={height}
                      placeholder="Altura em metros"
                    />
                  </Field>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row noGutters>
                <Col xs={12} md={6} className="p-0">
                  <EvolutionImage
                    reload={reloadAndClear}
                    instructions="Foto Frente"
                    setImage={setFrontImage}
                    id="01"
                  />
                </Col>
                <Col xs={12} md={6} className="p-0">
                  <EvolutionImage
                    reload={reloadAndClear}
                    instructions="Foto Lado Esquerdo"
                    setImage={setLeftImage}
                    id="02"
                  />
                </Col>
                <Col xs={12} md={6} className="p-0">
                  <EvolutionImage
                    reload={reloadAndClear}
                    instructions="Foto Lado Direito"
                    setImage={setRightImage}
                    id="03"
                  />
                </Col>
                <Col xs={12} md={6} className="p-0">
                  <EvolutionImage
                    reload={reloadAndClear}
                    instructions="Foto Costas"
                    setImage={setBackImage}
                    id="04"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="footer-buttons">
              <button type="button" className="btn" onClick={onSubmit}>
                {!submiting ? 'Salvar Alteração' : 'Salvando...'}
              </button>
            </Col>
          </FormStyledEvolution>
        </Row>
      </Col>
      <ShowMyEvolution reload={reloadAndClear} />
    </Row>
  );
};

export default MyEvolution;
