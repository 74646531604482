import React from 'react';
import {Container, Title} from './styles';
import KnowMore from '../../../KnowMore';

const SuggestedTrainingCard = ({content}) => {
  return (
    <Container>
      <Title>{content.title}</Title>
      <KnowMore className="know-more" to={`/cursos/${content.slug}/${content.id}`} />
    </Container>
  );
};

export default SuggestedTrainingCard;
