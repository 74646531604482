import React from 'react';
import {Container, Title, Percentage, Bar, PercentageBar} from './styles';

const TotalProgress = ({progress, className}) => {
  // eslint-disable-next-line no-nested-ternary
  const aux = progress ? (progress > 100 ? 100 : progress) : 0;
  return (
    <Container className={className || ''}>
      <Title>Progresso total</Title>
      <Percentage>{`${parseInt(aux, 10)}%`}</Percentage>
      <Bar>
        <PercentageBar style={{width: `${aux}%`}} />
      </Bar>
    </Container>
  );
};

export default TotalProgress;
