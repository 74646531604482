import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;
  .title {
    color: #0b111e;
  }
`;

export const Summary = styled.div`
  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 44px;
    font-weight: bold;
    margin: 0.67em 0;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: Roboto, sans-serif;
    color: #333;
  }
  p {
    margin-bottom: 25px;
    color: rgba(24, 24, 29, 0.7);
    font-size: 16px;
    line-height: 26px;
    margin-top: 0;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: Roboto, sans-serif;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #333;
  }
`;

export const Courses = styled.div``;
