/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {InternTitle, Empty} from '../styled';
import {getSeriesInProgress} from '../../../services/endpoints/courses/courses';
import InProgressCard from './InProgressCard';

const InProgress = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const reload = () => {
    setLoading(true);
    getSeriesInProgress()
      .then((response) => {
        const filter = response.data['hydra:member'].filter((item) => !!item.serie);
        setCourses(filter);
        setLoading(false);
      })
      .catch((error) => {
        console.error('LOADING.IN.PROGRESS.SERIES', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, []);

  return (
    <Row noGutters className="align-items-center">
      <Col xs={12} className="p-0 mb-4">
        <InternTitle>Todos seus cursos em andamento</InternTitle>
      </Col>
      {!loading ? (
        courses.length > 0 ? (
          courses.map((item, index) => (
            <Col xs={12} md={6} lg={4} key={index} className="mb-3 px-0 px-md-2">
              <InProgressCard content={item} />
            </Col>
          ))
        ) : (
          <Empty>Vazio</Empty>
        )
      ) : (
        <Col xs={12} className="d-flex justify-content-center my-5">
          <i className="far fa-spinner-third loading" />
        </Col>
      )}
    </Row>
  );
};

export default InProgress;
