import React, {useEffect} from 'react';
import {Container} from 'reactstrap';
import styled from 'styled-components';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';

const PageErrors = () => {
  const {setCurrentPage} = useCurrentPage();
  const {setPageBase} = usePageBase();

  useEffect(() => {
    setPageBase('');
    setCurrentPage('');
  }, [setCurrentPage, setPageBase]);

  return (
    <Main>
      <Container>
        <Box className="text-center mt-5 mb-5">
          <h1 className="hide-border">500</h1>
          <p>Ops! Ocorreu um erro em nosso sistema, tente novamente mais tarde.</p>
        </Box>
      </Container>
    </Main>
  );
};

const Main = styled.div`
  background: #002c63;
  height: 100vh;
  display: flex;
  flex: 1;
  overflow: hidden;
  color: #fff;
  align-items: center;
  h1 {
    color: #fff;
  }
`;

const Box = styled.div`
  position: relative;
`;

export default PageErrors;
