import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Title = styled.div`
  width: fit-content;
  font-size: 14px;
  color: #e8e8e8;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  min-width: 80px;
  border-bottom: 1px solid #2a2f3b;
  margin-bottom: 15px;
`;

export const Item = styled(Link)`
  font-size: 16px;
  color: #a9afaa !important;
  text-decoration: none !important;
  margin: 4px 0;
`;

export const Icon = styled.i`
  height: 35px;
  width: 35px;
  padding: 8px;
  border-radius: 6px;
  transition: 0.2s ease;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemSocial = styled.a`
  font-size: 16px;
  color: #a9afaa !important;
  text-decoration: none !important;
  margin: 4px 0;

  .Facebook,
  facebook {
    :hover {
      background-color: #284685;
    }
  }
  .Instagram,
  instagram {
    :hover {
      background-color: #c2338c;
    }
  }
  .Linkedin,
  linkedin {
    :hover {
      background-color: #0271ae;
    }
  }
`;
