import React from 'react';
import CourseCard from '../../../components/CourseCard';
import {useAlert} from 'react-alert';
import {ContainerCard, IconCard} from '../styled';
import {setSeeLater} from '../../../services/endpoints/courses/courses';

const SeeLaterCard = ({content, reload}) => {
  const alert = useAlert();
  const handleRemoveSeeLater = async () => {
    await setSeeLater({serie: content['@id']});
    reload();
    alert.show('Removido com sucesso de assistir mais tarde', {
      closeCopy: 'Ok',
    });
  };

  return (
    <ContainerCard>
      <IconCard
        className="far fa-clock"
        onClick={handleRemoveSeeLater}
        title="Remover curso de Assistir Mais Tarde"
      />
      <CourseCard content={content} />
    </ContainerCard>
  );
};

export default SeeLaterCard;
