import React, {useState} from 'react';
import * as Yup from 'yup';
import {Row, Col} from 'reactstrap';
import {useAlert} from 'react-alert';
import AvatarUpload from '../../../components/AvatarUpload';
import {FormStyled, InternTitle} from '../styled';
import GenerateForm from '../../../components/GenerateForm';
import {
  changePasswordUser,
  updateUser,
  updateAvatar,
} from '../../../services/endpoints/general/currentUser';
import {getCurrentUser, setCurrentUser} from '../../../utils/auth';

const PersonalData = () => {
  const [avatar, setAvatar] = useState();
  const alert = useAlert();
  const {profile} = getCurrentUser();

  const fieldsUser = [
    {
      name: '',
      fields: [
        {
          hasLabel: true,
          label: 'Seu nome',
          hasPlaceholder: true,
          placeholder: 'Digite o seu nome',
          name: 'name',
          value: profile.name,
          required: true,
          validations: {
            name: Yup.string().required('Campo obrigatório'),
          },
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: true,
          label: 'Número',
          hasPlaceholder: true,
          placeholder: '+55 (00) 9 0000-0000',
          name: 'phoneNumber',
          value: profile.phoneNumber || '',
          required: true,
          validations: {
            phoneNumber: Yup.string().required('Campo obrigatório'),
          },
          mask: '+99 (99) 9 9999-9999',
          maskType: 'phone',
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: true,
          label: 'E-mail',
          hasPlaceholder: true,
          placeholder: 'Digite o seu e-mail',
          name: 'email',
          value: profile.email || '',
          required: true,
          validations: {
            email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
          },

          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const fieldsPassword = [
    {
      fields: [
        {
          hasLabel: true,
          label: 'Alterar senha',
          hasPlaceholder: true,
          placeholder: 'Nova Senha',
          type: 'password',
          name: 'plainPassword',
          value: '',
          required: true,
          validations: {
            plainPassword: Yup.string()
              .min(8, 'Senha com no mínimo 8 caracteres')
              .max(24, 'Senha com no máximo 24 caracteres')
              .matches(/[a-z]/, 'Pelo menos um caractere minúsculo')
              .matches(/[A-Z]/, 'Pelo menos um caractere maiúsculo')
              .matches(/[0-9]/, 'Pelo menos um número')
              .matches(/[@$!%*#?&.<>]/, 'Pelo menos um caractere especial ex: @,$,!,%...'),
          },
          mask: '',
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: false,
          label: 'Confirmar Senha',
          hasPlaceholder: true,
          placeholder: 'Confirmar Senha',
          type: 'password',
          name: 'confirmPassword',
          value: '',
          required: true,
          validations: {
            confirmPassword: Yup.string().when('plainPassword', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('plainPassword')], 'As senhas não são iguais')
                .required('Campo obrigatório'),
            }),
          },
          mask: '',
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const settingsPassword = {
    button: {
      text: 'Salvar nova senha',
    },
  };
  const settingsUser = {
    button: {
      text: 'Salvar alteração',
    },
  };

  const onSubmit = async (values, actions) => {
    try {
      const newFile = new File([avatar], `${new Date().getTime()}.jpg`, {type: 'image/jpeg'});

      const data = new FormData();
      data.append('image', newFile);

      await updateAvatar(data);
    } catch (e) {
      console.error('Error uploadAvatarData:', e);
    }

    const data = {
      name: values.name,
      phoneNumber: values.phoneNumber.replace(/\D/g, ''),
      email: values.email,
    };

    try {
      const response = await updateUser(data);
      setCurrentUser({...getCurrentUser(), profile: response.data});

      alert.show('Dados salvos com sucesso!', {
        closeCopy: 'Ok',
      });
      actions.setSubmitting(false);
    } catch (e) {
      alert.show('Ocorreu um erro ao salvar os dados, tente novamente mais tarde!', {
        closeCopy: 'Ok',
      });
      actions.setSubmitting(false);
      console.error('Profile.onSubmit.updateUser', e);
    }

    actions.setSubmitting(false);
  };

  const onSubmitPassword = async (values, actions) => {
    try {
      // save password if exits
      if (values.plainPassword.length >= 8) {
        await changePasswordUser({
          plainPassword: values.plainPassword,
        });
      }
      alert.show('Senha alterada com sucesso!', {
        closeCopy: 'Ok',
      });
      actions.setSubmitting(false);
    } catch (e) {
      alert.show('Ocorreu um erro ao tentar alterar a senha, tente novamente mais tarde!', {
        closeCopy: 'Ok',
      });
      actions.setSubmitting(false);
      console.error('Profile.onSubmitPassword.updatePassword', e);
    }

    actions.setSubmitting(false);
  };

  return (
    <Row noGutters className="align-items-center">
      <Col xs={12}>
        <InternTitle>Meu perfil</InternTitle>
      </Col>
      <Col xs={12}>
        <AvatarUpload user={getCurrentUser()} setAvatar={setAvatar} />
      </Col>
      <Col xs={12}>
        <FormStyled>
          <Row noGutters>
            <Col xs={12} md={6} className="px-0 mb-5 mb-md-0">
              <GenerateForm onSubmit={onSubmit} groupFields={fieldsUser} settings={settingsUser} />
            </Col>
            <Col xs={12} md={6}>
              <GenerateForm
                onSubmit={onSubmitPassword}
                groupFields={fieldsPassword}
                settings={settingsPassword}
              />
            </Col>
          </Row>
        </FormStyled>
      </Col>
    </Row>
  );
};

export default PersonalData;
