import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {Row, Col} from 'reactstrap';
import axios from 'axios';
import {useAlert} from 'react-alert';
import validarCpf from 'validar-cpf';
import GenerateForm from '../../../../components/GenerateForm';
import {FormStyledCard, InternTitle} from '../../styled';
import {
  getPaymentProfiles,
  newPaymentProfile,
} from '../../../../services/endpoints/general/currentUser';
import api from '../../../../services/api';
import Card from './CardItem';
import {onlyNumbers, validCard} from '../../../../utils/functions';
import {vindiErrors} from '../../../../utils/vendi_help';

const CardData = () => {
  const [loading, setLoading] = useState(true);
  const [paymentProfiles, setPaymentProfiles] = useState([]);

  const alert = useAlert();

  const fieldsCard = [
    {
      name: '',
      fields: [
        {
          hasLabel: true,
          label: 'CPF',
          hasPlaceholder: true,
          placeholder: '000.***.***-**',
          name: 'identity',
          value: '',
          required: true,
          mask: '999.999.999-99',
          validations: {
            identity: Yup.string().required('Campo obrigatório').max(14, 'Máximo de 11 digitos'),
          },
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: true,
          label: 'Número do cartão',
          hasPlaceholder: true,
          placeholder: '0000 0000 0000 0000',
          name: 'card_number',
          value: '',
          required: true,
          validations: {
            card_number: Yup.string().required('Campo obrigatório').max(19, 'Máximo de 16 digitos'),
          },
          mask: '9999 9999 9999 9999',
          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: true,
          label: 'Nome escrito no cartão',
          hasPlaceholder: true,
          placeholder: 'Talita R.',
          name: 'card_name',
          value: '',
          required: true,
          validations: {
            card_name: Yup.string().required('Campo obrigatório'),
          },

          columns: {
            xs: 12,
          },
        },
        {
          hasLabel: true,
          label: 'Mês/ano',
          hasPlaceholder: true,
          placeholder: 'mm/aa',
          name: 'card_validity',
          value: '',
          required: true,
          mask: '99/99',
          validations: {
            card_validity: Yup.string()
              .required('Campo obrigatório')
              .min(5, 'Data de validade inválida')
              .max(5, 'Data de validade inválida'),
          },

          columns: {
            xs: 6,
          },
        },
        {
          hasLabel: true,
          label: 'Digito',
          hasPlaceholder: true,
          placeholder: 'cvv',
          name: 'security_code',
          value: '',
          mask: '9999',
          required: true,
          validations: {
            security_code: Yup.string()
              .required('Campo obrigatório')
              .min(3, 'CVV inválido')
              .max(4, 'CVV inválido'),
          },

          columns: {
            xs: 6,
          },
        },
      ],
    },
  ];

  const formSettings = {
    button: {
      text: 'Salvar',
    },
    delete: true,
    deleteTitle: 'Excluir cartão',
    icon: 'far fa-trash-alt',
  };

  const reloadPaymentProfiles = () => {
    setLoading(true);
    getPaymentProfiles()
      .then((response) => {
        const data = response.data['hydra:member'];
        setPaymentProfiles(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('ERROR.PAYMENT.PROFILES', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    reloadPaymentProfiles();
  }, []);

  const onSubmit = async (values, actions) => {
    if (values.identity) {
      // valida cpf
      const identity = onlyNumbers(values.identity);
      if (!validarCpf(identity)) {
        alert.show('CPF inválido. Insira um CPF válido e tente novamente.', {
          closeCopy: 'Ok',
        });
        actions.setSubmitting(false);
        return;
      }
    }

    if (values.card_validity) {
      // valida validade do cartao
      if (!validCard(values.card_validity)) {
        alert.show('Cartão vencido. Insira um cartão válido.', {
          closeCopy: 'Ok',
        });
        actions.setSubmitting(false);
        return;
      }
    }
    await api
      .get('/settings?section=vindi')
      .then((response) => {
        const data = response.data['hydra:member'];
        // eslint-disable-next-line prefer-const
        let settings = [];
        data.forEach((setting) => {
          settings[setting.name] = setting.value;
        });

        if (settings.vindi_api_url && settings.vindi_public_key) {
          const vindiPublicKey = btoa(settings.vindi_public_key);

          axios
            .post(
              `${settings.vindi_api_url}public/payment_profiles`,
              {
                holder_name: values.card_name.trim().toUpperCase(),
                card_expiration: values.card_validity.trim(),
                card_number: onlyNumbers(values.card_number),
                card_cvv: onlyNumbers(values.security_code),
                payment_method_code: 'credit_card',
              },
              {
                headers: {
                  Authorization: `Basic ${vindiPublicKey}`,
                },
              },
            )
            .then((paymentResponse) => {
              const dataToSend = {
                gatewayToken: paymentResponse.data.payment_profile.gateway_token,
              };
              newPaymentProfile(dataToSend)
                .then(() => {
                  actions.setSubmitting(false);
                  alert.show('Cartão salvo com sucesso.', {
                    closeCopy: 'Ok',
                  });
                  reloadPaymentProfiles();
                })
                .catch(() => {
                  actions.setSubmitting(false);
                  alert.show('Ocorreu um erro ao salvar cartão', {
                    closeCopy: 'Ok',
                  });
                });
            })
            .catch((error) => {
              alert.show(vindiErrors(error), {
                closeCopy: 'Ok',
              });
              console.error('handleSubmit.vindiPayment', error);
              actions.setSubmitting(false);
            });
        } else {
          alert.show('Estamos com problemas ao salvar o cartão, tente novamente mais tarde.', {
            closeCopy: 'Ok',
          });
          console.error('handleSubmit.vindi', 'vindi public key not found');
          actions.setSubmitting(false);
        }
      })
      .catch((error) => {
        console.error('handleSubmit.settings', error);
        actions.setSubmitting(false);
      });
  };

  return (
    <Row noGutters>
      <Col xs={12}>
        <InternTitle>Todos seus cartões salvos</InternTitle>
      </Col>
      {loading ? (
        <Col xs={12} className="d-flex justify-content-center my-4">
          <i className="far fa-spinner-third loading" />
        </Col>
      ) : (
        paymentProfiles.length > 0 &&
        paymentProfiles.map((item, index) => (
          <Col xs={12} md={6} key={index}>
            <Card content={item} reload={reloadPaymentProfiles} />
          </Col>
        ))
      )}
      <Col xs={12} className="mt-4">
        <InternTitle>Adicionar forma de pagamento</InternTitle>
      </Col>
      <Col xs={12} md={6} className="p-0">
        <FormStyledCard>
          <GenerateForm onSubmit={onSubmit} groupFields={fieldsCard} settings={formSettings} />
        </FormStyledCard>
      </Col>
    </Row>
  );
};

export default CardData;
