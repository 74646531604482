import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from './Link';
import {nameFormat} from '../../../utils/manipulateString';
import {getCurrentUser} from '../../../utils/auth';

const ProfileMenu = ({user, onClick}) => {
  const completeUser = getCurrentUser();
  return (
    <li style={{paddingRight: 20}}>
      <StyledLink to="#" className="profile-user">
        {completeUser.profile && completeUser.profile.avatar ? (
          <Avatar src={completeUser.profile.avatar.url} />
        ) : (
          <I className="icon-user fal fa-user-circle" title={nameFormat(user.name)} />
        )}
        Bem vindo (a),
        <br />
        {nameFormat(user.name)}
      </StyledLink>
      <ul className="ul-profile">
        <li style={{paddingRight: 20}}>
          <StyledLink to="#">
            {completeUser.profile && completeUser.profile.avatar ? (
              <Avatar src={completeUser.profile.avatar.url} />
            ) : (
              <I className="icon-user fal fa-user-circle" title={nameFormat(user.name)} />
            )}
            Bem vindo (a),
            <br />
            {nameFormat(user.name)}
          </StyledLink>
        </li>
        <li>
          <Link to="/perfil" onClick={onClick}>
            Meus dados
          </Link>
        </li>
        <li>
          <Link to="/meus-cursos" onClick={onClick}>
            Meus cursos
          </Link>
        </li>
        <li>
          <Link to="/sair" onClick={onClick}>
            Sair
          </Link>
        </li>
      </ul>
    </li>
  );
};

const I = styled.i`
  font-size: 20px !important;
`;

const StyledLink = styled(Link)`
  text-transform: none !important;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

ProfileMenu.proptTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProfileMenu;
