import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useAlert} from 'react-alert';
import {Field, Label, Data} from './styles';
import {getCardExpiration} from '../../../../utils/manipulateDates';
import {deleteProfile} from '../../../../services/endpoints/general/currentUser';
import {DeleteContainer} from '../../styled';

const Card = ({content, reload}) => {
  const [deleting, setDeleting] = useState(false);
  const alert = useAlert();

  const onDelete = () => {
    setDeleting(true);
    deleteProfile(content.id)
      .then(() => {
        setDeleting(false);
        alert.show('Cartão excluído com sucesso.', {
          closeCopy: 'Ok',
        });
        reload();
      })
      .catch(() => {
        setDeleting(false);
        alert.show('Erro ao excluír cartão. Tente novamente.', {
          closeCopy: 'Ok',
        });
      });
  };

  return (
    <Row>
      <Col xs={12}>
        <Field>
          <Label>Número do cartão</Label>
          <Data>{`**** **** **** ${content.cardNumberLastFour}`}</Data>
        </Field>
      </Col>
      <Col xs={12}>
        <Field>
          <Label>Nome escrito no cartão</Label>
          <Data>{content.holderName}</Data>
        </Field>
      </Col>
      <Col xs={12}>
        <Field>
          <Label>Mês/ano</Label>
          <Data>{getCardExpiration(content.cardExpiration)}</Data>
        </Field>
      </Col>
      <Col xs={12}>
        <DeleteContainer className="delete">
          <button
            type="button"
            style={{cursor: deleting ? 'wait' : 'pointer'}}
            disabled={deleting}
            onClick={() => onDelete()}
            title={`Excluir cartão ${content.holderName}`}>
            <i className="far fa-trash-alt" /> Excluir cartão
          </button>
        </DeleteContainer>
      </Col>
    </Row>
  );
};

export default Card;
