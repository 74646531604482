import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import Logo from '../../assets/images/logo.png';
import {BurguerMenu, Navbar, NavMenu, HeaderContent} from './styled';
import {getCurrentUser, getToken, redirectToLogin, redirectToSignUp} from '../../utils/auth';
import {Link} from './_partials/Link';
import ProfileMenu from './_partials/ProfileMenu';
import SearchButton from './_partials/SearchButton';

const Menu = ({content}) => {
  const {menuItems = []} = content;
  const [toogle, setToogle] = useState(!window.screen.width < 960);
  const {user} = getCurrentUser();
  const hasToken = getToken();
  const menus = [];

  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(0);
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    const el = document.getElementById('navtop');
    setHeight(window.screen.width < 960 ? 0 : el.offsetHeight);
    setScroll(window.scrollY);
  };
  useEffect(() => {
    const el = document.getElementById('navtop');

    setTop(el.offsetTop);
    setHeight(window.screen.width < 960 ? 0 : el.offsetHeight);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
  }, []);

  menuItems.forEach((menu) => {
    if (!menu.parentMenu) {
      menus.push(
        Object.assign(menu, {
          childrens:
            menuItems.filter(
              (children) => children.parentMenu && children.parentMenu.id === menu.id,
            ) || [],
        }),
      );
    }
  });

  const Item = ({item, onClick}) => {
    return (
      <li>
        <Link to={item.url} action={item.target} onClick={onClick}>
          {item.title}
          {item.childrens && item.childrens.length > 0 && <i className="far fa-angle-down" />}
        </Link>
        {item.childrens && item.childrens.length > 0 && (
          <ul>
            {item.childrens.map((children) => (
              <Item key={children.id} item={children} onClick={onClick} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <>
      <Navbar
        id="navtop"
        className={`d-flex flex-column flex-lg-row justify-content-lg-between ${
          scroll > top ? 'active' : ''
        }`}>
        <HeaderContent id="header-mobile">
          <Link className="logo-link" to="/" action="_self">
            <img src={Logo} alt="Meu Shape" />
          </Link>
          <BurguerMenu onClick={() => setToogle((prevValue) => !prevValue)}>
            <i className="far fa-bars" />
          </BurguerMenu>
        </HeaderContent>
        <NavMenu>
          <AnimateHeight duration={500} height={!toogle ? 'auto' : 0}>
            <ul>
              {menus.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  onClick={() => setToogle((prevValue) => !prevValue)}
                />
              ))}
              <li className="search-button">
                <SearchButton />
              </li>
              {hasToken ? (
                <>
                  <ProfileMenu user={user} onClick={() => setToogle((prevValue) => !prevValue)} />
                </>
              ) : (
                <>
                  <li>
                    <button
                      type="button"
                      className="a"
                      onClick={() => {
                        redirectToLogin();
                      }}>
                      Login
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        redirectToSignUp();
                      }}>
                      Cadastre-se
                    </button>
                  </li>
                </>
              )}
            </ul>
          </AnimateHeight>
        </NavMenu>
      </Navbar>
      <div className="extra-padding" style={{paddingBottom: height}} />
    </>
  );
};

Menu.propTypes = {
  content: PropTypes.object.isRequired,
};

export default Menu;
