import React from 'react';
import {Link} from 'react-router-dom';
import {Row} from 'reactstrap';
import {Logo} from './styles';
import LogoMeuShape from '../../assets/images/logo.png';

const DefaultMenuFooter = () => {
  return (
    <Row noGutters className="d-flex justify-content-center justify-content-md-end">
      <Link to="/">
        <Logo src={LogoMeuShape} alt="Meu Shape" />
      </Link>
    </Row>
  );
};

export default DefaultMenuFooter;
