import Cookie from 'js-cookie';
import axios from 'axios';
import {asyncLocalStorage} from './asyncLocalStorage';

function getToken() {
  return Cookie.get(process.env.REACT_APP_TOKEN);
}
function deleteToken() {
  return Cookie.remove(process.env.REACT_APP_TOKEN);
}

function getCurrentUser() {
  if (getToken()) {
    return JSON.parse(localStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`));
  }
  return {
    user: {},
    profile: {},
  };
}

function setToken(token) {
  Cookie.set(process.env.REACT_APP_TOKEN, token);
}

async function refreshToken(clientId) {
  const refreshtoken = Cookie.get(process.env.REACT_APP_REFRESH_TOKEN);
  if (refreshtoken) {
    try {
      const response = await axios.post(
        'https://cognito-idp.us-east-1.amazonaws.com',
        {
          ClientId: clientId,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: refreshtoken,
          },
        },
        {
          headers: {
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            'Content-Type': 'application/x-amz-json-1.1',
          },
        },
      );
      const newToken = response.AuthenticationResult.IdToken;
      setToken(newToken);
      return newToken;
    } catch (error) {
      console.error('refresh.token', error);
      return null;
    }
  }
  return null;
}

async function getAsyncCurrentUser() {
  return asyncLocalStorage.getItem(`${process.env.REACT_APP_PREFIX}.user`);
}

function setCurrentUser(data) {
  localStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data));
}

function setAsyncCurrentUser(data) {
  return asyncLocalStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(data));
}

const goToWebsite = process.env.REACT_APP_WEBSITE_URL;
const getLogoutPage = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
  document.location.origin,
)}`;
const getLoginPage = `${process.env.REACT_APP_SIGNIN_URL}?login=${btoa(document.location.href)}`;

const getSignUpPage = `${process.env.REACT_APP_SIGNIN_URL}/cadastro`;

function redirectToLogin(msg = '') {
  localStorage.clear();
  deleteToken();
  window.location = `${getLogoutPage}${msg ? `&msg=${msg}` : ''}`;
  return null;
}
function redirectToSignUp(msg = '') {
  localStorage.clear();
  deleteToken();
  window.location = `${getSignUpPage}${msg ? `&msg=${msg}` : ''}`;
  return null;
}

function refreshLogin() {
  localStorage.clear();
  window.location = `${getLoginPage}`;
}

export {
  getToken,
  getCurrentUser,
  getAsyncCurrentUser,
  setCurrentUser,
  setAsyncCurrentUser,
  redirectToLogin,
  refreshLogin,
  redirectToSignUp,
  getLoginPage,
  getLogoutPage,
  goToWebsite,
  refreshToken,
};
