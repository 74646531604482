import api from '../../api';

const getSubscriptions = (params = {}) =>
  api.get('/current_customer_subscriptions', {
    params: {
      ...params,
    },
  });

const getSubscription = (id, params = {}) =>
  api.get(`current_customer_subscriptions/${id}`, {
    params: {
      ...params,
    },
  });

const cancelSubscription = (id, data) =>
  api.post(`current_customer_subscriptions/${id}/cancel`, data);

export {getSubscriptions, getSubscription, cancelSubscription};
