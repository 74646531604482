import styled from 'styled-components';

export const FormStyled = styled.div`
  .group-field {
    margin-bottom: 20px;

    > label {
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
  & textarea {
    min-height: 150px;
  }

  .element-field {
    margin-bottom: 15px;

    .label-title {
      display: block;
      margin-bottom: 5px;
      letter-spacing: 2px;
    }

    .form-control:not([type='radio']) {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      padding: 8px 12px;
      display: block;
      width: 100%;
    }
    span {
      font-size: 14px;
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ContactContainer = styled.div`
  .title {
    color: #0b111e;
  }

  .btn {
    font-size: 16px;
    padding: 12px 20px;
    :hover {
      opacity: 0.85;
    }
  }
`;

export const SendedContainer = styled.div`
  padding: 20px 0;
  background-color: #dddddd;
  display: flex;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #333;
  line-height: 20px;
`;
