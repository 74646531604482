import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {Container, Row, Col} from 'reactstrap';
import Pagination from 'react-js-pagination';
import {Helmet} from 'react-helmet';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import {hasProperty} from '../../utils/functions';
import {settings} from '../../config/settings';
import {searchPosts, searchSeries} from '../../services/endpoints/general/search';
import BlogCard from '../../components/BlogCard';
import CourseCard from '../../components/CourseCard';
import Title from '../../components/Title';
import {SearchContainer} from './styles';
import Search from '../Search';

const SingleSearch = ({match, location, history}) => {
  const {search = ''} = match.params;
  const {perPage} = settings;
  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const params = queryString.parse(location.search);
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1;

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(settings.pageBase);

  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const parameters = {
          perPage,
          page: currentPage,
          search: params.search || '',
        };
        let response;

        if (search === 'blog') {
          response = await searchPosts(parameters);
        } else {
          response = await searchSeries(parameters);
        }

        setTotalItems(response.data['hydra:totalItems']);
        setItems(response.data['hydra:member']);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [params.search, currentPage]);

  const handleChangePage = (page) => {
    history.push({search: queryString.stringify({page})});
  };

  return (
    <SearchContainer>
      <Container className="pl-2 p-lg-0">
        <Helmet>
          <title>{`${settings.titleSite}: ${params.search}`}</title>
        </Helmet>
        <Title style={{color: '#0b111e'}}>Buscas em {search}</Title>
        <h2 className="mb-4">Resultados para: {params.search}</h2>
        <Row noGutters className="mb-4">
          {!loading ? (
            <Col xs={12}>
              <Row noGutters>
                {items.length ? (
                  items.map((item, index) => (
                    <Col xs={12} md={6} lg={4} className="p-0 pr-md-3 mb-3" key={index}>
                      {search === 'blog' ? (
                        <BlogCard blogPage content={item} noHome />
                      ) : (
                        <CourseCard blogPage content={item} home />
                      )}
                    </Col>
                  ))
                ) : (
                  <Col xs={12}>
                    <h3>Nenhum {search === 'blog' ? 'post' : 'curso'} encontrando até o momento</h3>
                  </Col>
                )}
              </Row>
            </Col>
          ) : (
            <Col xs={12} className="d-flex justify-content-center">
              <i className="far fa-spinner-third loading" />
            </Col>
          )}
        </Row>
        {!!items.length && totalItems > perPage && (
          <Pagination
            {...settings.pagination}
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalItems}
            onChange={handleChangePage}
          />
        )}
      </Container>
    </SearchContainer>
  );
};

export default SingleSearch;
