import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import TeacherCard from '../TeacherCard';
import {Container as ContainerTeacher} from './styles';

const Teachers = ({content}) => {
  const teachers = content['hydra:member'].filter((teacher) => teacher.showInstructor);

  return (
    <ContainerTeacher>
      <Container className="pl-4 pl-lg-0">
        <Row noGutters className="mt-3">
          {teachers &&
            teachers.map((teacher, index) => (
              <Col key={index} xs={6} lg={4} className="mb-1 pr-4">
                <TeacherCard content={teacher} />
              </Col>
            ))}
        </Row>
      </Container>
    </ContainerTeacher>
  );
};

export default Teachers;
