import React from 'react';
import {useAlert} from 'react-alert';
import CourseCard from '../../../components/CourseCard';
import {ContainerCard, IconCard} from '../styled';
import {setLike} from '../../../services/endpoints/courses/courses';

const LikedCard = ({content, reload}) => {
  const alert = useAlert();
  const handleRemoveLiked = async () => {
    await setLike(content.id);
    reload();
    alert.show('Gostei removido com sucesso', {
      closeCopy: 'Ok',
    });
  };

  return (
    <ContainerCard>
      <IconCard
        className="fas fa-times"
        onClick={handleRemoveLiked}
        title="Remover gostei do curso"
      />
      <CourseCard content={content} />
    </ContainerCard>
  );
};

export default LikedCard;
