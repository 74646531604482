import React from 'react';
import Tag from '../Tag';
import By from './_partials/By';
import {
  Container,
  BackgroundImage,
  Shadow,
  Title,
  ContainerTitle,
  StyledLink,
  Blocked,
  Message,
  Lock,
  ChoosePlan,
} from './styles';
import KnowMore from '../KnowMore';
import NoImageUser from '../../assets/images/noUser.png';
import noImage from '../../assets/images/noImagePost.jpeg';
import {getToken} from '../../utils/auth';

const Card = ({content}) => {
  const free = content.hasFreeEpisodes;

  const logged = !!getToken();
  return (
    <Container to={`/cursos/${content.slug}/${content.id}`}>
      {content.tags.length > 0 && content.tags[0].title && <Tag>#{content.tags[0].title}</Tag>}
      <StyledLink to={`/cursos/${content.slug}/${content.id}`}>
        <BackgroundImage src={content.image ? content.image.url : noImage} />
        {!logged && !free && <Lock className="fas fa-lock-alt" />}
      </StyledLink>
      <Shadow className="position-none position-lg-absolute">
        <By
          name={content.instructor ? content.instructor.name : ''}
          image={
            content.instructor && content.instructor.avatar
              ? content.instructor.avatar.url
              : NoImageUser
          }
        />
        <ContainerTitle>
          <Title to={`/cursos/${content.slug}/${content.id}`}>{content.title}</Title>
        </ContainerTitle>
        <KnowMore className="know-more" to={`/cursos/${content.slug}/${content.id}`} />
      </Shadow>
      {!logged && !free && (
        <Blocked>
          <i className="fas fa-lock-alt" />
          <Message>
            Este conteúdo é exclusivo, clique abaixo para se cadastrar e escolher o seu plano de
            assinatura.
          </Message>
          <ChoosePlan to="/login">Assinatura</ChoosePlan>
        </Blocked>
      )}
    </Container>
  );
};

export default Card;
