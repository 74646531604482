import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {
  SearchIcon,
  ContainerSearchStyle,
  InputDesktop,
  ContainerFormDesktop,
  SeachButtonLink,
} from './styles';

const SearchButton = () => {
  const [query, setQuery] = useState('');
  const [active, setActive] = useState(false);
  const refLink = useRef(null);

  const search = () => {
    if (query) {
      setActive(false);
      refLink.current.click();
      setQuery('');
    }
  };

  return (
    <>
      <SearchIcon
        onClick={() => {
          setActive((prev) => !prev);
        }}
        className={`far fa-search d-flex align-items-center justify-content-center ${
          active ? 'open' : ''
        }`}>
        <ContainerSearchStyle className={`search-box ${active ? 'open' : ''}`}>
          <ContainerFormDesktop>
            <InputDesktop
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              placeholder="Pesquisar em todo o site"
            />
            <SeachButtonLink disabled={!query} onClick={search}>
              Buscar
            </SeachButtonLink>
            <Link
              ref={refLink}
              style={{display: 'none'}}
              to={{pathname: '/busca', search: `search=${query}`}}
            />
          </ContainerFormDesktop>
        </ContainerSearchStyle>
      </SearchIcon>
    </>
  );
};

export default SearchButton;
