import styled from 'styled-components';

export const Copyright = styled.label`
  font-size: 12px;
  color: #878787;
  cursor: text;
`;

export const Container = styled.div`
  margin-top: 60px;
`;
