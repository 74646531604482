import styled from 'styled-components';

export const QuestionsContainer = styled.div`
  border: 1px solid #36b5ab;
  border-radius: 20px;
  margin: 0 25px;
  padding-left: 35px;
  padding-right: 35px;
  .btn {
    display: block;
    width: 100%;
    margin: 40px 0;
    :hover {
      background-color: #2d958d;
    }
    :disabled {
      background-color: #2d958d;
      opacity: 0.8;
      cursor: wait;
    }
  }
`;
export const Title = styled.h1`
  text-align: center;
  margin: 20px 0;
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
export const SubTitle = styled.p`
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;

  @media (min-width: 991px) {
    padding: 0 100px !important;
  }
`;

export const FormStyled = styled.div`

  input {
    display: block;
    width: 100%;
    padding: 13px 0;
    padding-right: 13px;
    font-size: 14px;
    border-style: none none solid;
    border-width: 1px 1px 2px;
    border-color: #000 #000 hsla(0, 0%, 100%, 0.26);
    border-radius: 0px;
    color: #fff;
    margin-bottom: 13px;
    font-weight: 300;
    transition: border-color 265ms cubic-bezier(0.165, 0.84, 0.44, 1);
    font-family: Roboto, sans-serif;

    :hover {
      border-bottom-color: hsla(0, 0%, 100%, 0.56);
    }
    :focus {
      border-bottom-color: #fff;
    }
    ::placeholder {
      color: white;
      font-size: 16px;
    }
  }

  .title {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const ContainerField = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  input {
    width: fit-content;
    margin-right: 10px;
    width: 100%;
  }
`;
export const ContainerBottomComponents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

// anamense

export const QuestionGroup = styled.div`
  margin-bottom: 20px;
`;

export const Question = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  color: white;
`;

export const Alternatives = styled.label`
  display: block;
  margin-bottom: 10px;
  color: white;
  input {
    margin-right: 10px;
  }
`;

export const Hint = styled.div`
  color: #d3d3d3;
  margin-top: 10px;
  margin-bottom: 10px;
`;
