import React, {useEffect, useState} from 'react';
import {Row, Col} from 'reactstrap';
import Pagination from 'react-js-pagination';
import {InternTitle, ActualData, ImageStyled, LabelShowEvolution, Info} from '../../styled';
import {getMyEvolution} from '../../../../services/endpoints/general/currentUser';
import {settings} from '../../../../config/settings';
import {getDate} from '../../../../utils/manipulateDates';

const ShowMyEvolution = ({reload}) => {
  const [evolutions, setEvolutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const perPage = 2;

  const load = (AuxPage = 1) => {
    setLoading(true);
    const params = {
      perPage,
      page: AuxPage,
      'order[evolutionDate]': 'desc',
    };
    getMyEvolution(params)
      .then((response) => {
        setEvolutions(response.data['hydra:member']);
        setTotalItems(response.data['hydra:totalItems']);
        setLoading(false);
      })
      .catch((error) => {
        console.error('GET.MY.EVOLUTION', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    load();
    return () => {
      setEvolutions([]);
    };
  }, [reload]);

  return (
    <Row noGutters>
      {!loading ? (
        <>
          {evolutions.length > 0 &&
            evolutions.map((item, index) => (
              <Row className="mt-5" key={index} noGutters>
                <Col xs={6} className="p-0 px-md-2">
                  <InternTitle>Minha evolução</InternTitle>
                </Col>
                <Col xs={6} className="p-0 px-md-2 d-flex justify-content-end">
                  <ActualData>Data: {getDate(item.evolutionDate)}</ActualData>
                </Col>
                <Row noGutters className="px-md-2">
                  <Col xs={item.age ? 4 : 6}>
                    <LabelShowEvolution>Peso</LabelShowEvolution>
                    <Info>{item.weight} kg</Info>
                  </Col>
                  <Col xs={item.age ? 4 : 6}>
                    <LabelShowEvolution>Altura</LabelShowEvolution>
                    <Info>{item.height} m</Info>
                  </Col>
                  {item.age && (
                    <Col xs={4}>
                      <LabelShowEvolution>Idade</LabelShowEvolution>
                      <Info>{item.age} anos</Info>
                    </Col>
                  )}
                </Row>
                <Row noGutters>
                  {item.frontPhoto && (
                    <>
                      <Col
                        xs={12}
                        md={6}
                        className="mt-3 px-md-2 d-flex justify-content-center jusitfy-content-md-start">
                        <ImageStyled src={item.frontPhoto.url} alt="Imagem frontal" />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="mt-3 px-md-2 d-flex justify-content-center jusitfy-content-md-start">
                        <ImageStyled src={item.leftSidePhoto.url} alt="Imagem do lado esquerdo" />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="mt-3 px-md-2 d-flex justify-content-center jusitfy-content-md-start">
                        <ImageStyled src={item.rightSidePhoto.url} alt="Imagem do lado direito" />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="mt-3 px-md-2 d-flex justify-content-center jusitfy-content-md-start">
                        <ImageStyled src={item.backPhoto.url} alt="Imagem das costas" />
                      </Col>
                    </>
                  )}
                </Row>
              </Row>
            ))}
          {!!evolutions.length > 0 && totalItems > perPage && (
            <Row noGutters className="d-flex justify-content-center mt-3">
              <Pagination
                {...settings.pagination}
                style
                activePage={page}
                itemsCountPerPage={perPage}
                totalItemsCount={totalItems}
                onChange={(auxPage) => {
                  load(auxPage);
                  setPage(auxPage);
                }}
              />
            </Row>
          )}
        </>
      ) : (
        <Col xs={12} lg={8} className="d-flex justify-content-center">
          <i className="far fa-spinner-third loading" />
        </Col>
      )}
    </Row>
  );
};

export default ShowMyEvolution;
