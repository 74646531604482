import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
`;
export const Message = styled.h2`
  color: #0b111e !important;
  height: 30vh;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 5px;
`;

export const LiveContainer = styled.div``;
