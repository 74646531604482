import React, {useEffect} from 'react';
import {Container} from 'reactstrap';
import {useCurrentPage} from '../../contexts/PageContext';

const NotFound = () => {
  const {setCurrentPage} = useCurrentPage();
  useEffect(() => {
    setCurrentPage('');
  }, [setCurrentPage]);
  return (
    <Container>
      <div className="text-center mt-5 mb-5">
        <h1 className="hide-border">Pagina não encontrada</h1>
        <p>Lamentamos, mas a página que você está procurando não existe.</p>
      </div>
    </Container>
  );
};

export default NotFound;
