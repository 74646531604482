import styled, {keyframes} from 'styled-components';
import {Col} from 'reactstrap';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  display: block;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin-top: 20px;
  color: rgb(51, 51, 51);
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const Data = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  padding: 8px 12px;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #36b5ab;
  color: #333;
  outline: none;
  margin-bottom: 15px;
  background-color: #e9e9e9;
`;

export const SubscriptionContainer = styled.div`
  .title {
    color: #0b111e;
  }
`;

export const Info = styled.p`
  margin-bottom: 25px;
  color: rgba(24, 24, 29, 0.7);
  font-size: 16px;
  line-height: 26px;
  margin-top: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Roboto, sans-serif;
`;

const moveDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  90% {
    opacity: 1;
  }
  to {
    transform: translateY(0px);
  }
`;
const moveUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  90% {
    opacity: 1;
  }
  to {
    transform: translateY(0px);
  }
`;

export const SubscriptionItemContainer = styled(Col)`
  background-color: white;
  border: solid 0px #0b111e;
  padding: 20px 30px !important;
  margin-bottom: 10px;
  padding-top: 5px !important;

  &:hover {
    border-color: #36b5ab;
  }

  .inputAnimation {
    animation: ${moveDown} 500ms;
    animation-fill-mode: backwards;
  }

  .buttontAnimation {
    animation: ${moveUp} 500ms;
    animation-fill-mode: backwards;
  }
`;

export const SubscriptionTitle = styled.h3`
  font-size: 26px;
  margin-bottom: 15px;
  -webkit-letter-spacing: 8px;
  -moz-letter-spacing: 8px;
  -ms-letter-spacing: 8px;
  letter-spacing: 8px;
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
  padding-top: 15px;
  word-break: break-word;
`;

export const ItemPrice = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: #777;
`;

export const ContainerLabel = styled.div`
  align-items: baseline;
  display: flex;
`;

export const StatusLabel = styled.p`
  margin-top: 20px;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const Status = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #333;
`;

export const CancelButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  border: 3px solid #36b5ab;
  border-radius: 20px;
  color: #333;
  margin-top: 20px;
  background-color: transparent;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;

  :hover {
    color: white;
    background-color: #36b5ab;
  }
`;

export const BackButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #36b5ab;
  border-radius: 20px;
  color: white;
  margin-top: 20px;
  background-color: #36b5ab;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;

  :hover {
    color: #333;
    background-color: white;
  }
`;

export const ConfirmButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  border: 1px solid rgb(237, 235, 76);
  border-radius: 20px;
  color: #333;
  background-color: rgb(237, 235, 76);
  margin-top: 20px;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;

  :hover {
    color: #333;
    background-color: white;
  }
`;

export const InputLabel = styled.label`
  margin-top: 20px;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.textarea`
  background-color: transparent;
  border: 1px solid #36b5ab;
  color: #333;
  outline: none;
  margin-bottom: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  padding: 8px 12px;
  display: block;
  width: 100%;
  min-height: 100px;
  margin-top: 10px;
`;
export const SimpleButton = styled.button`
  border: none;
  background-color: transparent;
  color: #36b5ab;
  font-size: 14px;
  font-weight: 900;
  margin: 15px 25px;
  text-transform: uppercase;
  cursor: pointer;
`;
