import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  background-color: #36b5ab;
  padding: 20px 25px;
  border-radius: 5px;
  padding: 20px 36px 20px 20px;

  .know-more {
    color: black !important;
  }

  :hover {
    h1,
    h3 {
      color: black;
    }
    background: rgb(237, 235, 76);
    background: linear-gradient(
      60deg,
      rgba(237, 235, 76, 1) 0%,
      rgba(54, 181, 171, 1) 50%,
      rgba(3, 158, 195, 1) 90%
    );
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  color: white;
  letter-spacing: 8px;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-variant: small-caps;
  line-height: 67px;
  padding-left: 10px;
  word-break: break-word;
`;
