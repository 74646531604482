import api from '../../api';

export const getInstructor = (id, params = {}) =>
  api.get(`/instructors/${id}`, {
    params: {
      ...params,
    },
  });

export const getInstructors = (params) =>
  api.get(`/instructors/`, {
    params: {
      ...params,
    },
  });

export const getInstructorSeries = (params) =>
  api.get(`/series/`, {
    params: {
      ...params,
    },
  });
