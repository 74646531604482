import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {Container, Row, Col} from 'reactstrap';
import Pagination from 'react-js-pagination';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import {hasProperty, filterCategories, filterAuthors} from '../../utils/functions';
import {normalAutorName} from '../../utils/manipulateString';
import {settings} from '../../config/settings';
import {getPosts, getPostsCategories} from '../../services/endpoints/blog/posts';
import {getInstructors} from '../../services/endpoints/instructor';
import BlogCard from '../../components/BlogCard';
import {ContainerContent, ContainerCategories, ExternContainer} from './styles';
import BlogCategories from '../../components/BlogCategories';
import Title from '../../components/Title';

const Blog = ({match, location, history}) => {
  const {category = ''} = match.params;
  const {perPage} = settings;
  const [totalItems, setTotalItems] = useState(0);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const params = queryString.parse(location.search);
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1;

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setPageBase(settings.pageBase);

  setCurrentPage('listagem-de-posts');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const parameters = {
          perPage,
          page: currentPage,
        };

        if (category) {
          Object.assign(parameters, {categorySearch: category});
        }

        if (params.autor) {
          Object.assign(parameters, {instructorSearch: normalAutorName(params.autor)});
        }

        const response = await getPosts(parameters);

        setTotalItems(response.data['hydra:totalItems']);
        setPosts(response.data['hydra:member']);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [category, currentPage, params.autor]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.all([getInstructors(), getPostsCategories()]);

        const authors = filterAuthors(response[0].data['hydra:member']);
        const categoriesAux = filterCategories(response[1].data['hydra:member']);

        setCategories([...categoriesAux, {title: 'Autores', children: authors}]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleChangePage = (page) => {
    history.push({search: queryString.stringify({page})});
  };

  return (
    <ExternContainer>
      <Container className="pl-2 p-lg-0">
        <Helmet>
          <title>{`${settings.titleSite}: Blog`}</title>
        </Helmet>
        <Title style={{color: '#0b111e'}}>blog</Title>
        <Row noGutters>
          {!loading ? (
            <Col xs={12} lg={8}>
              <ContainerContent>
                <Row noGutters>
                  {posts.length ? (
                    posts.map((item, index) => (
                      <Col xs={12} lg={6} className="p-0 pr-lg-3 mb-3" key={index}>
                        <BlogCard blogPage content={item} noHome />
                      </Col>
                    ))
                  ) : (
                    <Col xs={12}>
                      <h3 style={{color: '#333'}}>
                        Ainda não temos conteúdos publicados para esta página.
                      </h3>
                    </Col>
                  )}
                </Row>
              </ContainerContent>
            </Col>
          ) : (
            <Col xs={12} lg={8} className="d-flex justify-content-center">
              <i className="far fa-spinner-third loading" />
            </Col>
          )}

          <Col xs={12} lg={4} className="mt-5 mt-lg-0">
            <ContainerCategories className="p-0 pl-lg-5">
              <BlogCategories content={categories} />
            </ContainerCategories>
          </Col>
        </Row>
        {!!posts.length && totalItems > perPage && (
          <Pagination
            {...settings.pagination}
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalItems}
            onChange={handleChangePage}
          />
        )}
      </Container>
    </ExternContainer>
  );
};

export default Blog;
