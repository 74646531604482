import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useAlert} from 'react-alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {priceFormat} from '../../../../utils/functions';
import {cancelSubscription} from '../../../../services/endpoints/subscription';

import {
  SubscriptionItemContainer,
  SubscriptionTitle,
  ItemPrice,
  Status,
  StatusLabel,
  ContainerLabel,
  CancelButton,
  InputLabel,
  Input,
  ConfirmButton,
  BackButton,
  SimpleButton,
} from './styles';

const SubscriptionItem = ({content}) => {
  const [showInput, setShowInput] = useState(false);
  const [reason, setReason] = useState('');
  const [canceling, setCanceling] = useState(false);
  const [open, setOpen] = React.useState(false);

  const alert = useAlert();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const traducao = (name) => {
    switch (name) {
      case 'future':
        return 'Trial';
      case 'waiting':
        return 'Aguardando';
      case 'created':
        return 'Criado';
      case 'canceled':
        return 'Cancelado';
      case 'paid':
        return 'Efetuado';
      case 'active':
        return 'Ativo';
      default:
        return name;
    }
  };

  const cancelSubscriptionFunc = (id) => {
    setCanceling(true);
    cancelSubscription(id, {
      cancellationReason: reason,
    })
      .then((response) => {
        alert.show(response.data.message, {
          closeCopy: 'Ok',
          onClose: () => {
            window.location = window.location.origin;
          },
        });
      })
      .catch(() => {
        alert.show('Erro no cancelamento da inscrição. Tente novamente', {
          closeCopy: 'Ok',
        });
      })
      .finally(() => {
        setCanceling(false);
      });
  };

  return (
    <SubscriptionItemContainer xs={12}>
      <Row noGutters className="d-flex justify-content-between">
        <Col xs={12} md={6}>
          <Row noGutters>
            <Col xs={12}>
              <SubscriptionTitle>{content.planName}</SubscriptionTitle>
            </Col>
            <Col xs={12}>
              <ItemPrice>{priceFormat(content.price)}</ItemPrice>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row noGutters>
            <Col xs={12} className="d-flex justify-content-md-end">
              <ContainerLabel>
                <StatusLabel>Status:</StatusLabel>
                <Status>{traducao(content.status)}</Status>
              </ContainerLabel>
            </Col>
            <Col xs={12} className="d-flex justify-content-md-end">
              <ContainerLabel>
                <StatusLabel>Pagamento:</StatusLabel>
                <Status>{traducao(content.billingStatus)}</Status>
              </ContainerLabel>
            </Col>
          </Row>
        </Col>
      </Row>
      {content.cancelable && (
        <Row noGutters className="d-flex justify-content-end">
          {!showInput ? (
            <Col xs={12} md={5} lg={3} className="buttontAnimation">
              <CancelButton onClick={() => setShowInput(true)}>Cancelar inscrição</CancelButton>
            </Col>
          ) : (
            <Col xs={12} className="inputAnimation">
              <InputLabel>
                Poderia nos dizer qual o motivo do cancelamento da sua inscrição?
              </InputLabel>
              <Input
                onChange={(event) => setReason(event.target.value)}
                placeholder="Motivo do cancelamento..."
              />
              <Row noGutters className="d-flex justify-content-end">
                <Col xs={12} md={6} lg={3}>
                  <BackButton
                    className="mt-2"
                    disabled={canceling}
                    onClick={() => setShowInput(false)}>
                    Voltar
                  </BackButton>
                </Col>
                <Col xs={12} md={6} lg={3} className="mr-md-2">
                  <ConfirmButton className="mt-2" onClick={handleClickOpen} disabled={canceling}>
                    Enviar
                  </ConfirmButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Cancelar assinatura?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cancelando a sua assinatura você não terá acesso a toda plataforma.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SimpleButton
            onClick={handleClose}
            color="primary"
            style={{cursor: canceling ? 'wait' : 'pointer'}}>
            Não
          </SimpleButton>
          <SimpleButton
            onClick={() => cancelSubscriptionFunc(content.id)}
            color="primary"
            style={{cursor: canceling ? 'wait' : 'pointer'}}>
            Sim
          </SimpleButton>
        </DialogActions>
      </Dialog>
    </SubscriptionItemContainer>
  );
};

export default SubscriptionItem;
