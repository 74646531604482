import React, {useCallback, useEffect, useState} from 'react';
import queryString from 'query-string';
import {Container, Row, Col} from 'reactstrap';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';
import {hasProperty, filtrarEpisodes} from '../../utils/functions';
import {settings} from '../../config/settings';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import Title from '../../components/Title';
import {SearchContainer, ButtonSingleSearch} from './styles';
import {searchPosts, searchSeries, searchEpisodes} from '../../services/endpoints/general/search';
import SerieCard from '../../components/CourseCard';
import BlogCard from '../../components/BlogCard';
import SubTitle from './_partials/SubTitle';

const Search = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [courses, setCourses] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const perPage = 6;

  const [totalItemsSerie, setTotalItemsSerie] = useState(0);
  const [totalItemsPosts, setTotalItemsPosts] = useState(0);
  const currentPage = hasProperty(query, 'page') ? parseInt(query.page, 10) : 1;

  const {setCurrentPage} = useCurrentPage();
  const {setPageBase} = usePageBase();

  setPageBase(settings.pageBase);
  setCurrentPage('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchContents = useCallback(async () => {
    setLoading(true);
    const params = {
      perPage,
      page: currentPage,
      search: query.search,
    };
    try {
      const response = await axios.all([
        searchPosts(params),
        searchSeries(params),
        searchEpisodes(params),
      ]);

      const postsAux = response[0].data;
      const coursesAux = response[1].data;
      const episodesAux = response[2].data['hydra:member'];

      const filteredEpisodes = filtrarEpisodes(episodesAux);

      setCourses([...coursesAux['hydra:member'], ...filteredEpisodes]);
      setPosts(postsAux['hydra:member']);

      setTotalItemsSerie(coursesAux['hydra:totalItems']);
      setTotalItemsPosts(postsAux['hydra:totalItems']);
      setLoading(false);
    } catch (e) {
      console.error('Search.searchContents', e);
      setLoading(false);
    }

    setLoading(false);
  }, [query]);

  useEffect(() => {
    searchContents();
  }, [query.search, currentPage]);

  return (
    <SearchContainer>
      <Helmet>
        <title>{`${settings.titleSite}: ${query.search}`}</title>
      </Helmet>
      <Container>
        <Title className="title">Buscas</Title>
        <Row noGutters className="mb-3">
          <Col xs={12}>
            <h1>Resultados para: {query.search}</h1>
          </Col>
          {!loading ? (
            <>
              <Col xs={12}>
                <Row noGutters>
                  <Col xs={12}>
                    <SubTitle className="title">Cursos</SubTitle>
                  </Col>
                  {courses.length > 0 ? (
                    courses.map((course, index) => (
                      <Col xs={12} md={6} lg={4} key={index} className="pr-md-3 mb-3">
                        <SerieCard content={course} />
                      </Col>
                    ))
                  ) : (
                    <Col xs={12}>
                      <h2>Nenhum resultado encontrado</h2>
                    </Col>
                  )}
                  {!!courses.length && totalItemsSerie > perPage && (
                    <Col xs={12} className="d-flex justify-content-center">
                      <ButtonSingleSearch
                        to={{pathname: '/busca/cursos', search: `search=${query.search}`}}>
                        Mostrar mais
                      </ButtonSingleSearch>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={12} className="mt-3">
                <Row noGutters>
                  <Col xs={12}>
                    <SubTitle className="title">Posts no blog</SubTitle>
                  </Col>
                  {posts.length > 0 ? (
                    posts.map((post, index) => (
                      <Col xs={12} md={6} lg={4} key={index} className="pr-md-3 mb-3">
                        <BlogCard content={post} noHome />
                      </Col>
                    ))
                  ) : (
                    <Col xs={12} className="mb-5">
                      <h2>Nenhum resultado encontrado</h2>
                    </Col>
                  )}
                  {!!posts.length && totalItemsPosts > perPage && (
                    <Col xs={12} className="d-flex justify-content-center">
                      <ButtonSingleSearch
                        to={{pathname: '/busca/blog', search: `search=${query.search}`}}>
                        Mostrar mais
                      </ButtonSingleSearch>
                    </Col>
                  )}
                </Row>
              </Col>
            </>
          ) : (
            <Col xs={12} className="d-flex justify-content-center my-4">
              <i className="far fa-spinner-third loading" />
            </Col>
          )}
        </Row>
      </Container>
    </SearchContainer>
  );
};

export default Search;
