/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect} from 'react';
import StripTags from 'striptags';
import {Container, Row, Col} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {useAlert} from 'react-alert';
import axios from 'axios';
import {
  Title,
  QuestionsContainer,
  SubTitle,
  FormStyled,
  ContainerField,
  ContainerBottomComponents,
} from './styles';
import AnamneseBase from '../AnamneseBase';
import {getAnamnesis, postAnamnesis} from '../../services/endpoints/anamnesis/anamnesis';
import {setNewEvolution} from '../../services/endpoints/general/currentUser';
import {Questions} from './Questions';
import {getCurrentUser} from '../../utils/auth';

const AnamneseQuestions = () => {
  const alert = useAlert();
  const [anamnese, setAnamnese] = useState({});
  const [anamnenseStateQuestions, setAnamnenseStateQuestions] = useState({});
  const [age, setAge] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    getAnamnesis()
      .then((response) => {
        setAnamnese(response.data || {});
      })
      .catch((error) => {
        console.error('GET.ANAMENESIS.ACTIVE', error);
      });
    return () => {
      setAnamnese([]);
    };
  }, []);

  const user = getCurrentUser();
  if (user.hasCompletedAnamnesisForm) {
    return <Redirect to="/" />;
  }

  const topQuestions = () => {
    return (
      <ContainerBottomComponents>
        <ContainerField>
          <input
            type="number"
            value={age}
            placeholder="Sua Idade"
            onChange={(e) => setAge(e.target.value)}
          />
        </ContainerField>
        <ContainerField>
          <input
            type="number"
            value={height}
            placeholder="Altura"
            onChange={(e) => setHeight(e.target.value)}
          />
        </ContainerField>
        <ContainerField>
          <input
            type="number"
            value={weight}
            placeholder="Peso"
            onChange={(e) => setWeight(e.target.value)}
          />
        </ContainerField>
      </ContainerBottomComponents>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!age || !weight || !height) {
      alert.show('É necessário responder todas as perguntas', {
        closeCopy: 'Ok',
      });
      return;
    }

    const questionsId = anamnese.questions.map((q) => q.id);
    const questionsIdAnswers = [];

    const answers = Object.values(anamnenseStateQuestions).map((q) => {
      const tmp = q.split(',');

      if (!questionsIdAnswers.includes(parseInt(tmp[0], 10))) {
        questionsIdAnswers.push(parseInt(tmp[0], 10));
      }

      return {
        anamnesis_question_id: parseInt(tmp[0], 10),
        anamnesis_question_alternative_id: parseInt(tmp[1], 10),
      };
    });

    if (JSON.stringify(questionsId.sort()) !== JSON.stringify(questionsIdAnswers.sort())) {
      alert.show('É necessário responder todas as perguntas', {
        closeCopy: 'Ok',
      });
      return false;
    }
    setSubmiting(true);

    const data = new FormData();
    data.append('weight', parseFloat(weight));
    data.append('height', parseFloat(height));
    data.append('age', parseInt(age, 10));

    axios
      .all([setNewEvolution(data), postAnamnesis(anamnese.id, answers)])
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        setSubmiting(false);
        console.error('error no post', error);
        const {data = undefined} = error.response;

        if (
          data &&
          data.hasOwnProperty('hydra:description') &&
          data['hydra:description'] === 'Usuário já preencheu o formulário.'
        ) {
          alert.show(data['hydra:description'], {
            closeCopy: 'Ok',
          });
          window.location.href = '/';
        } else if (data && data.hasOwnProperty('hydra:description')) {
          alert.show(data['hydra:description'], {
            closeCopy: 'Ok',
          });
        } else {
          alert.show('Ocorreu um erro ao cadastrar sua ficha, tente novamente mais tarde.', {
            closeCopy: 'Ok',
          });
        }
        console.error('Questions.handleSubmit', error);
      });
  };

  return (
    <AnamneseBase>
      <Container>
        <Row noGutters className="d-flex justify-content-center">
          <Col xs={12} md={10} lg={7} className="mx-3">
            <QuestionsContainer className="py-3">
              <Title>{anamnese.title}</Title>
              <SubTitle dangerouslySetInnerHTML={{__html: StripTags(anamnese.description)}} />
              <FormStyled>{topQuestions()}</FormStyled>
              {Object.keys(anamnese).length > 0 && (
                <Questions
                  anamneseId={anamnese.id}
                  questions={anamnese.questions}
                  setState={setAnamnenseStateQuestions}
                  handleSubmit={handleSubmit}
                  submiting={submiting}
                />
              )}
            </QuestionsContainer>
          </Col>
        </Row>
      </Container>
    </AnamneseBase>
  );
};

export default AnamneseQuestions;
