import api from '../../api';

const getSettings = (params = {}) => {
  return api.get(`/settings`, {
    params: {
      ...params,
    },
  });
};
const getFeature = (id, params = {}) => {
  return api.get(`/features/${id}`, {
    params: {
      ...params,
    },
  });
};

export {getSettings, getFeature};
