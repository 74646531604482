import React from 'react';
import {settings} from '../../config/settings';
import {Copyright, Container} from './styles';

const Copyrights = () => {
  return (
    <Container>
      <Copyright>{settings.copyright}</Copyright>
    </Container>
  );
};

export default Copyrights;
