import styled from 'styled-components';

export const StyledLink = styled.a`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(14, 14, 14, 0.05);
  -webkit-transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: border 400ms ease, -webkit-transform 400ms ease;
  transition: transform 400ms ease, border 400ms ease;
  transition: transform 400ms ease, border 400ms ease, -webkit-transform 400ms ease;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  padding: 20px;

  i {
    font-size: 30px;
  }
  span {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 20px;
    color: #000;
    font-size: 12px;
    text-align: left;
  }

  .pdf {
    color: #fa1212;
  }

  .img {
    color: #1c9c24;
  }
`;
