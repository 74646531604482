import React from 'react';
import {positions, Provider} from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import Routes from './routes';

const options = {
  position: positions.MIDDLE,
};

function App() {
  return (
    <Provider template={AlertMUITemplate} {...options}>
      <Routes />
    </Provider>
  );
}

export default App;
