import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  background-color: #36b5ab;
  padding: 20px 25px;
  border-radius: 5px;

  .know-more {
    color: black !important;
  }

  :hover {
    h1,
    h3 {
      color: black;
    }
    background: rgb(237, 235, 76);
    background: linear-gradient(
      60deg,
      rgba(237, 235, 76, 1) 0%,
      rgba(54, 181, 171, 1) 50%,
      rgba(3, 158, 195, 1) 90%
    );
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: white;
  letter-spacing: 5px;
  font-size: 26px;
  font-weight: 700;
  ine-height: 67px;
  text-transform: uppercase;
  margin: 0;
  font-variant: small-caps;
  word-break: break-word;
`;

export const Price = styled.h3`
  color: white;
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 10px;
`;
