import styled from 'styled-components';

export const Title = styled.div`
  width: fit-content;
  font-size: 14px;
  color: #e8e8e8;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  min-width: 80px;
  border-bottom: 1px solid #2a2f3b;
  margin-bottom: 15px;
`;
