import styled from 'styled-components';

export const AnamneseContainer = styled.div`
  position: relative;
  background-color: #0b111e;
  background-image: linear-gradient(180deg, rgba(11, 17, 30, 0.33), rgba(11, 17, 30, 0.33)),
    linear-gradient(180deg, rgba(54, 181, 171, 0.3), #0b111e);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;
`;

export const BackgroundImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0
  right: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(11, 17, 30, 0.33), rgba(11, 17, 30, 0.33)),
    linear-gradient(180deg, rgba(54, 181, 171, 0.3), #0b111e),
    url('https://assets.website-files.com/5ebab15f00de7426d732dd15/5ed5661629d1e84347cfdeee_b2%20(1).jpg');
  background-position: 0px 0px, 0px 0px, 0px 0px;
  background-size: auto, auto, cover;
 
`;

export const ContainerLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 197px;
  margin: 20px 0;
`;
