import React from 'react';
import PropTypes from 'prop-types';
import {Row} from 'reactstrap';
import {Icon, ItemSocial} from '../styles';

const SocialItem = ({href = '', className = '', children = '', title = ''}) => {
  return (
    <Row noGutters className="d-flex align-items-center">
      <ItemSocial href={href}>
        <Icon className={`${className}`} title={title} />
      </ItemSocial>
      <ItemSocial href={href}>{children}</ItemSocial>
    </Row>
  );
};

SocialItem.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SocialItem;
