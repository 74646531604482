import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Spinner} from 'reactstrap';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import MobileWebView from './pages/MobileWebView';
import './assets/styles/sass/base.scss';
import App from './App';

ReactDOM.render(
  <Suspense
    fallback={
      <div className="init-loading text-center">
        <Spinner />
      </div>
    }>
    <BrowserRouter>
      <Switch>
        <Route
          path="/mobile/:reference/:content/:playerKey/:progress/:token"
          exact
          component={MobileWebView}
        />
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);
