import styled from 'styled-components';

export const PlansContainer = styled.div`
  z-index: 1;

  .big {
    border-style: solid;
    border-width: 5px;
    border-color: #36b5ab;
    margin-right: -25px;
    margin-left: -25px;
    padding: 35px 10px;
  }
  .col-active {
    z-index: 2;
  }
`;

export const Title = styled.h3`
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
`;

export const Description = styled.p`
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 60px;
`;
