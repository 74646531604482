import React from 'react';
import CourseCard from '../../../components/CourseCard';
import {ContainerCard} from '../styled';
import ProgressBar from '../../../components/TotalProgress';

const InProgressCard = ({content}) => {
  return (
    <ContainerCard>
      <CourseCard content={content.serie} />
      <ProgressBar className="progress" progress={content.progressPercent} />
    </ContainerCard>
  );
};

export default InProgressCard;
