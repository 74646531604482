import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled(Link)`
  text-decoration: none !important;
  margin-bottom: 20px;
`;
export const TeacherImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const TeacherName = styled.label`
  font-size: 18px;
  color: #0b111e !important;
  line-height: 24px;
  margin-top: 25px;
  text-align: center;
  cursor: pointer;
`;

export const TeacherOf = styled.label`
  color: #36b5ab !important;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 70%;
`;

export const ImageInternContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
