import styled from 'styled-components';

export const Title = styled.label`
  color: #333;
  font-weight: 400;
  letter-spacing: 4px;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 10px 22px;
  background-color: #fff;
  margin: 10px 0;
`;
