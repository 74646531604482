import React, {useEffect, useState, useRef} from 'react';
import StripTags from 'striptags';
import {Row, Col, Container} from 'reactstrap';
import {Description, ImgApp, ContainerStore, StoreImg, ContainerApp} from './styles';
import Title from '../Title';

const BlockApp = ({content, block}) => {
  const [animation, setAnimation] = useState(false);
  const element = useRef(null);

  const onScroll = () => {
    const activeAnimation =
      element.current.offsetTop <=
      document.documentElement.scrollTop + document.documentElement.clientHeight - 100;

    if (activeAnimation) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll());
  }, []);

  return (
    <ContainerApp ref={element}>
      <Container className="px-4 px-md-0">
        <Row noGutters>
          <Col
            xs={12}
            md={6}
            className={`mt-5 order-2 order-md-1 ${animation ? 'animation-right' : ''}`}>
            <Title>{block.title}</Title>
            <Description
              className="pr-0 pr-md-5"
              dangerouslySetInnerHTML={{__html: StripTags(block.description)}}
            />
            <ContainerStore className="d-flex justify-content-center justify-content-md-start">
              <a href="https://www.apple.com.br">
                <StoreImg
                  src="https://assets.website-files.com/5ebab15f00de7426d732dd15/5ef4c5d833aa2c822d29709b_app-store.png"
                  alt="Apple Store"
                />
              </a>
              <a href="https://www.google.com.br">
                <StoreImg
                  src="https://assets.website-files.com/5ebab15f00de7426d732dd15/5ef4c6760e8c9137b1e17191_play-store-p-500.png"
                  alt="App Store"
                />
              </a>
            </ContainerStore>
          </Col>
          <Col
            xs={12}
            md={6}
            className="order-1 order-md-2 d-flex justify-content-center justify-content-md-start">
            <ImgApp
              src={content.url}
              alt="aplicativo"
              className={`${animation ? 'animation-left' : ''}`}
            />
          </Col>
        </Row>
      </Container>
    </ContainerApp>
  );
};

export default BlockApp;
