import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled.div``;
export const InternContainer = styled.div``;

export const StyledHr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #ebedee;
  border: none;
`;
export const Title = styled(Link)`
  button {
    color: #333 !important;
    padding: 10px 22px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 4px;
    line-height: 20px;
    background-color: #36b5ab !important;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  margin-bottom: 10px;
`;

export const CategorieItem = styled(Link)`
  text-decoration: none !important;
  color: #0b111e;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;

  :hover {
    color: #5d5d60;
  }
`;

export const CategorieName = styled.span`
  display: block;
  opacity: 0.7;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 30px;
`;
