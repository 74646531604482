/* eslint-disable max-len */
import styled from 'styled-components';

export const ContainerPlan = styled.div`
  padding: 270px 0;
  background-image: linear-gradient(180deg, transparent, #0b111e),
    linear-gradient(180deg, #0b111e 14%, rgba(0, 0, 0, 0.68)),
    url(https://assets.website-files.com/5ebab15f00de7426d732dd15/5ed653f531da3059ed38cc7b_banner-11.png);
  background-position: 0px 0px, 0px 0px, 0px 0px;
  background-size: auto, auto, cover;

  display: flex;
  flex-direction: column;
  .shape-container {
    position: relative;

    z-index: 0;
    .shape {
      position: absolute;
      left: -290px;
      min-width: 80%;
      top: -133px;
      left: -70px;
    }
  }
  .items {
    z-index: 2;
  }
  @media (max-width: 991px) {
    padding: 90px 0;
    .items {
      order: 2;
      margin-top: 50px;
    }
    .shape {
      position: static !important;
      order: 1;
      min-width: initial !important;
    }
  }
`;
