import api from '../../api';

const getPosts = (params = {}) =>
  api.get('/posts', {
    params: {
      ...params,
    },
  });
const getPost = (id, params = {}) =>
  api.get(`/posts/${id}`, {
    params: {
      ...params,
    },
  });
const getAssociatedPosts = (params = {}) =>
  api.get(`/posts`, {
    params: {
      ...params,
    },
  });

const getPostsCategories = () => api.get('/post_categories');

export {getPosts, getPostsCategories, getPost, getAssociatedPosts};
