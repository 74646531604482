const Components = {};

Components.Banner = require('../Banner').default;
Components.Faq = require('../Faq').default;
Components.Menu = require('../Menu').default;
Components.BlockPlan = require('../BlockPlan').default;
Components.BlockSuggestedTraining = require('../BlockSuggestedTraining').default;
Components.BlockBlog = require('../BlockBlog').default;
Components.BlockApp = require('../BlockApp').default;
Components.BlockCourses = require('../BlockCourses').default;
Components.BlockToYou = require('../BlockToYou').default;
Components.MenuFooter = require('../MenuFooter').default;
Components.RedesSociais = require('../RedesSociais').default;
Components.Info = require('../Info').default;
Components.Teachers = require('../Teachers').default;
Components.Live = require('../Live').default;
Components.AnamnesePlans = require('../AnamnesePlans').default;
Components.AnamnesePayment = require('../AnamnesePayment').default;
Components.AnamneseQuestions = require('../AnamneseQuestions').default;

export default Components;
