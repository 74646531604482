import api from '../../api';

export const searchSeries = (params) =>
  api.get(`/series`, {
    params,
  });
export const searchPosts = (params) =>
  api.get(`/posts`, {
    params,
  });
export const searchEpisodes = (params) =>
  api.get(`/episodes`, {
    params,
  });
