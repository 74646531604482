import React, {useEffect, useState, useRef} from 'react';
import {Col, Row, Container} from 'reactstrap';
import StripTags from 'striptags';
import Slider from 'react-slick';
import {SubTitle, PrevArrowStyled, NextArrowStyled, ContainerSlider, ContainerBlog} from './styles';
import KnowMore from '../KnowMore';
import TitleWhite from '../BlogTitle';
import BlogCard from '../BlogCard';

const Blog = ({content, block}) => {
  const blogItems = content['hydra:member'];
  const [animation, setAnimation] = useState(false);
  const element = useRef(null);

  const onScroll = () => {
    if (!element.current) {
      return;
    }
    const activeAnimation =
      element.current.offsetTop <=
      document.documentElement.scrollTop + document.documentElement.clientHeight - 100;

    if (activeAnimation) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  const PrevArrow = ({onClick}) => {
    return <PrevArrowStyled className="fal fa-angle-left" onClick={onClick} />;
  };

  const NextArrow = ({onClick}) => {
    return <NextArrowStyled className="fal fa-angle-right" onClick={onClick} />;
  };

  return (
    <ContainerBlog ref={element}>
      <Container className="pl-4 px-md-0">
        <Row noGutters>
          <Col xs={6} className={`${animation ? 'animation-right' : ''}`}>
            <Row noGutters>
              <TitleWhite>{block.title}</TitleWhite>
              <Col xs={12}>
                <SubTitle dangerouslySetInnerHTML={{__html: StripTags(block.description)}} />
              </Col>
              <Col xs={12}>
                <KnowMore style={{marginLeft: 0}} text="ver tudo no blog" to="/blog" />
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="p-0">
            <Row noGutters>
              <Col xs={12} md={11} lg={11}>
                <ContainerSlider>
                  <Slider
                    infinite={blogItems.length > 1}
                    slidesToShow={2}
                    slidesToScroll={1}
                    arrows
                    speed={500}
                    draggable
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                        },
                      },
                      {
                        breakpoint: 800,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          arrows: false,
                        },
                      },
                    ]}>
                    {blogItems &&
                      blogItems.length &&
                      blogItems.map((item, index) => (
                        <Col className="px-2" key={index}>
                          <BlogCard content={item} />
                        </Col>
                      ))}
                  </Slider>
                </ContainerSlider>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ContainerBlog>
  );
};

export default Blog;
