import React, {useState, useEffect} from 'react';
import {Container, Col, Row} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {settings} from '../../../../config/settings';
import {useCurrentPage, usePageBase} from '../../../../contexts/PageContext';
import {getSubscriptions} from '../../../../services/endpoints/subscription';
import {getCurrentUser} from '../../../../utils/auth';
import ItemSubscription from './SubscriptionItem';
import {InternTitle} from '../../styled';

const Subscription = () => {
  const [loadingSub, setLoadingSub] = useState(true);
  const [subscriptions, setSubscriptions] = useState();

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setCurrentPage('');
  setPageBase(settings.pageBase);

  // remove cancelled and created subscriptions of view
  const filterSubscriptions = (customerSubscriptions) => {
    return customerSubscriptions.filter(
      (subscription) => subscription.status !== 'canceled' && subscription.status !== 'created',
    );
  };

  const load = () => {
    setLoadingSub(true);
    getSubscriptions()
      .then((response) => {
        setSubscriptions(filterSubscriptions(response.data['hydra:member']));
      })
      .catch((error) => console.error('LOAD.SUBSCRIPTIONS', error))
      .finally(() => {
        setLoadingSub(false);
      });
  };

  useEffect(() => {
    load();
  }, []);

  const user = getCurrentUser();
  if (!user.hasActiveSubscription) {
    return <Redirect to="/planos" />;
  }

  return (
    <Container className="pl-2 p-lg-0">
      <InternTitle>Assinatura</InternTitle>
      <Row noGutters className="mb-4 mt-2">
        {!loadingSub ? (
          <>
            {subscriptions.length > 0 ? (
              subscriptions.map((item, index) => (
                <ItemSubscription content={item} key={index} reload={load} />
              ))
            ) : (
              <Redirect to="/planos" />
            )}
          </>
        ) : (
          <Col xs={12} className="d-flex justify-content-center">
            <i className="far fa-spinner-third loading" />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Subscription;
