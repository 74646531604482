import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {hasExternalLink} from '../../utils/utilities';
import {BannerImage, BannerMask, Description, MetaInfo, MetaInfoContent, Title} from './styled';

const BannerItem = ({banner, noMetaInfo}) => {
  const MyLink = (props) => {
    const {children} = props;

    if (hasExternalLink(banner.link)) {
      return (
        <a href={banner.link || '/'} target={banner.action || '_self'} rel="noopener noreferrer">
          {props.children}
        </a>
      );
    }

    return <Link to={banner.link}>{children}</Link>;
  };

  return (
    <MyLink>
      <BannerMask>
        {!!banner.image && <BannerImage src={banner.image} alt={banner.title} />}
      </BannerMask>
      {!noMetaInfo && (
        <MetaInfo className="animation-top-down">
          <div className="container d-flex justify-content-center">
            <MetaInfoContent>
              <Title>{banner.title}</Title>
              <Description dangerouslySetInnerHTML={{__html: banner.description}} />
            </MetaInfoContent>
          </div>
        </MetaInfo>
      )}
    </MyLink>
  );
};

BannerItem.propTypes = {
  banner: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(BannerItem);
