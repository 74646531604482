import styled from 'styled-components';

export const ExternContainer = styled.div`
  background-color: #0b111e;
  padding: 80px 0;
`;
export const ContainerInfo = styled.div`
  width: 100%;
  background: linear-gradient(90deg, rgba(54, 181, 171, 1) -10%, rgba(237, 235, 76, 1) 95%);
  padding: 30px 15px;
  border-radius: 5px;

  .btn {
    font-size: 14px;
    line-height: inherit;
    padding: 12px 30px;
  }
`;
export const Title = styled.h1`
  margin-top: 10px !important;
  color: #333333;
  font-size: 26px;
  letter-spacing: 8px;
  text-transform: uppercase;
  margin: 0;
  font-variant: small-caps;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin: 15px 0;
  font-weight: 400;
  color: #0b111e;
  max-width: 420px;
`;
