import styled from 'styled-components';

export const Box = styled.div``;
export const Header = styled.header``;
export const HeaderContent = styled.div``;

export const Main = styled.div``;

export const InnerMain = styled.div`
  .title {
    color: #0b111e;
  }
`;
export const ContentShow = styled.div``;

export const ContentPage = styled.div``;

export const Footer = styled.div`
  background-color: #0b111e;
  padding: 50px 0;
`;
export const Copyright = styled.div``;

export const ContainerFooter = styled.div`
  padding: 20px;
`;
