import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 -1px 20px 0 rgba(15, 15, 15, 0.4);
`;

export const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  :hover {
    opacity: 0.85;
  }
  @media (max-width: 768px) {
    height: inherit;
  }
`;

export const Shadow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;

  .know-more {
    margin: 0;
  }

  background-color: rgba(11, 17, 30, 0.6);
  :hover {
    background-color: rgba(11, 17, 30, 0.44);
  }
`;

export const ContainerTitle = styled.div`
  padding: 10px 0;
  flex: 1;
  height: 0;
  overflow: hidden;
`;

export const Title = styled(Link)`
  font-size: 24px;
  font-weight: 300;
  text-decoration: none !important;
  color: white;
  line-height: 34px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  :hover {
    color: #cfcfd0;
  }
`;
export const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  display: block;
  &:before {
    content: '';
    padding-bottom: 60%;
    height: 0;
    display: block;
  }
`;

export const Blocked = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;

  i {
    font-size: 18px;
  }
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export const Message = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  padding: 0 10px;
`;
export const Lock = styled.i`
  font-size: 14px;
  color: white;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
`;

export const ChoosePlan = styled(Link)`
  font-size: 1.2em;
  background-color: white;
  padding: 10px 20px;
  border-radius: 7px;
  color: #0b111e;
  text-decoration: none;
  border: 1px solid white;
  :hover {
    background-color: transparent;
    color: white;
    border-color: white;
  }
`;
