import React from 'react';
import {Row, Col} from 'reactstrap';
import {Container, Title, Price, ButtonChange} from './styles';
import {priceFormat} from '../../../../utils/functions';

const PlanCard = ({content, onSelect, toggleModal, modal, className = ''}) => {
  const price = content.planItems.reduce(
    (accumulator, planItem) => accumulator + planItem.price,
    0,
  );

  return (
    <Container className={`d-flex justify-content-between align-items-center ${className}`}>
      <Row noGutters>
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center justify-content-md-start">
          <Title>{content.name}</Title>
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex align-items-center justify-content-center justify-content-start">
          <Price>{priceFormat(price)}</Price>
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex align-items-center justify-content-center justify-content-md-end">
          <ButtonChange
            style={{color: modal ? '#fff' : '#52646d'}}
            onClick={() => (modal ? onSelect(content) : toggleModal())}>
            {modal ? 'Adicionar' : 'Trocar'}
          </ButtonChange>
        </Col>
      </Row>
    </Container>
  );
};

export default PlanCard;
