import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const ExternContainer = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #0b111e;
  margin-bottom: 20px;
  position: initial;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
`;

export const Wallpaper = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  min-width: 120px;
`;

export const Tag = styled.label`
  font-size: 11px;
  padding-top: 5px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 16px !important;
`;
export const Title = styled.label`
  font-size: 18px;
  line-height: 25px;
  text-transform: none;
  color: white;
  font-weight: bold;
  flex: 1 1;
  overflow: hidden;
  max-height: 75px;
  text-overflow: ellipsis!important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ContainerWallpaper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  &:before {
    content: '';
    padding-bottom: 60%;
    height: 0;
    display: block;
  }
`;
