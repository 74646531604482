import React, {useState, useEffect} from 'react';
import {Container, Col, Row} from 'reactstrap';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import {Helmet} from 'react-helmet';
import {hasProperty, filterCategories} from '../../utils/functions';
import {settings} from '../../config/settings';
import {useCurrentPage, usePageBase} from '../../contexts/PageContext';
import {getSeries, getCategories} from '../../services/endpoints/courses/courses';
import {toNormalCase as toNormal} from '../../utils/manipulateString';
import Title from '../../components/Title';
import CourseCategories from '../../components/CourseCategories';
import CourseCard from '../../components/CourseCard';
import {CoursesContainer} from './styles';

const Courses = ({match, location, history}) => {
  const {category = ''} = match.params;
  const {perPage} = settings;
  const [totalItems, setTotalItems] = useState(0);
  const [episodes, setEpisodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const title = toNormal(location.pathname.split('/cursos/')[1]) || 'Todos os cursos';
  const titlePage = title === 'nutricao' ? 'Nutrição' : title;

  const params = queryString.parse(location.search);
  const currentPage = hasProperty(params, 'page') ? parseInt(params.page, 10) : 1;

  const {setPageBase} = usePageBase();
  const {setCurrentPage} = useCurrentPage();

  setCurrentPage('listagem-de-cursos');
  setPageBase(settings.pageBase);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const parameters = {
          perPage,
          page: currentPage,
        };

        if (category) {
          Object.assign(parameters, {categorySearch: category});
        }
        const response = await getSeries(parameters);

        setTotalItems(response.data['hydra:totalItems']);
        setEpisodes(response.data['hydra:member']);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [category, currentPage]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getCategories();

        const categoriesAux = filterCategories(response.data['hydra:member']);

        setCategories(categoriesAux);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleChangePage = (page) => {
    history.push({search: queryString.stringify({page})});
  };

  return (
    <CoursesContainer>
      <Helmet>
        <title>{`${settings.titleSite}: ${titlePage}`}</title>
      </Helmet>
      <Container className="pl-2 p-lg-0">
        <Title className="title">{titlePage}</Title>
        <Row noGutters className="mt-4">
          {!loading ? (
            <Col xs={12} lg={8}>
              <Row noGutters>
                {episodes.length > 0 ? (
                  episodes.map((course, index) => (
                    <Col xs={12} lg={6} key={index} className="p-0 pr-lg-4 mb-4">
                      <CourseCard content={course} />
                    </Col>
                  ))
                ) : (
                  <Col xs={12}>
                    <h3 style={{color: '#333'}}>
                      Ainda não temos conteúdos publicados para esta página
                    </h3>
                  </Col>
                )}
              </Row>
            </Col>
          ) : (
            <Col xs={12} lg={8} className="d-flex justify-content-center">
              <i className="far fa-spinner-third loading" />
            </Col>
          )}

          <Col xs={12} lg={4} className="pl-4">
            <CourseCategories content={categories} />
          </Col>
        </Row>
        {!!episodes.length > 0 && totalItems > perPage && (
          <Pagination
            {...settings.pagination}
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalItems}
            onChange={handleChangePage}
          />
        )}
      </Container>
    </CoursesContainer>
  );
};

export default Courses;
