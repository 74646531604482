import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {GuardedRoute, GuardProvider} from 'react-router-guards';

import {getToken, redirectToLogin, refreshLogin} from './utils/auth';
import {authenticator} from './services/auth';

import PageProvider from './contexts/PageContext';
import AnamneseProvider from './contexts/Anamnese';
import AuthProvider from './services/providers/AuthProvider';

import Pages from './pages';
import NotFound from './pages/NotFound';
import PageErrors from './pages/Errors';
import Profile from './pages/Profile';
import Courses from './pages/Courses';
import Blog from './pages/Blog';
import Base from './components/Base';
import Contact from './pages/Contact';
import Search from './pages/Search';
// import Products from './pages/Products';
import CourseShow from './pages/CourseShow';
import TeacherInternPage from './pages/TeacherInternPage';
import BlogShow from './pages/BlogShow';
import SingleSearch from './pages/SingleSearch';
import MyCourses from './pages/MyCourses';

const testUrl = async (to, from, next) => {
  const blackList = [
    '/sair',
    '/perfil',
    '/planos',
    '/checkout',
    '/ficha-anamnese',
    '/cursos/:category/:slug',
  ];

  if (getToken() || blackList.includes(to.location.pathname)) {
    try {
      await authenticator();
      next();
    } catch (e) {
      console.error('Erro ao autenticar o usuário');
    }
  }

  next();
};

const Routes = () => {
  return (
    <Router>
      <GuardProvider>
        <PageProvider>
          <AuthProvider>
            <AnamneseProvider>
              <Base>
                <Switch>
                  <GuardedRoute path="/" exact component={Pages} />
                  <GuardedRoute path="/404" component={NotFound} />
                  <GuardedRoute path="/erros" component={PageErrors} />
                  <GuardedRoute path="/contato" component={Contact} />

                  <GuardedRoute path="/cursos/:category/:slug" component={CourseShow} />
                  <GuardedRoute path="/cursos/:category" component={Courses} />
                  <GuardedRoute path="/cursos/" component={Courses} />

                  <GuardedRoute path="/busca/:search" component={SingleSearch} />
                  <GuardedRoute path="/busca" component={Search} />

                  <GuardedRoute path="/blog/:slug/:id" component={BlogShow} />

                  <GuardedRoute path="/blog/:category" component={Blog} />
                  <GuardedRoute path="/blog" component={Blog} />
                  <GuardedRoute path="/login" exact component={() => refreshLogin()} />
                  <GuardedRoute path="/professores/:slug/:id" exact component={TeacherInternPage} />

                  {
                    // <GuardedRoute path="/produtos" component={Products} />
                  }

                  <GuardProvider guards={[testUrl]}>
                    <Switch>
                      <GuardedRoute path="/perfil" exact component={Profile} />
                      <GuardedRoute path="/meus-cursos" exact component={MyCourses} />
                      <GuardedRoute path="/sair" exact component={() => redirectToLogin()} />

                      <GuardedRoute path="/:slug" exact component={Pages} />
                      <GuardedRoute path="*" component={NotFound} />
                    </Switch>
                  </GuardProvider>
                </Switch>
              </Base>
            </AnamneseProvider>
          </AuthProvider>
        </PageProvider>
      </GuardProvider>
    </Router>
  );
};

export default Routes;
