/* eslint-disable no-prototype-builtins */
import React from 'react';
import {Question, QuestionGroup, Hint as HintStyled} from './styles';
import {Alternatives} from './Alternatives';

const Questions = ({questions, handleSubmit, setState, submiting}) => {
  const buttonConfig = {
    text: 'Tudo pronto, vamos começar?',
    textSubmitting: 'começando...',
  };
  const Hint = (allowedAnswers) => {
    if (allowedAnswers > 1) {
      return <HintStyled>Escolha {allowedAnswers} opções.</HintStyled>;
    }
    return <HintStyled>Assinale a opção que mais tem a ver com você hoje.</HintStyled>;
  };

  const handleChange = (event) => {
    const {name, value} = event.target;
    if (name.indexOf('checkbox') !== -1) {
      setState((old) => {
        if (old.hasOwnProperty(name)) {
          delete old[name];
          return old;
        }
        return {...old, [name]: value};
      });
    } else {
      setState((old) => ({...old, [name]: value}));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {questions.map((question) => (
        <QuestionGroup key={question.id}>
          <Question>{question.title}</Question>
          <Hint allowedAnswers={question.allowedAnswers} />
          <Alternatives
            handleChange={handleChange}
            questionId={question.id || 0}
            alternatives={question.alternatives || []}
            allowedAnswers={question.allowedAnswers || 0}
          />
        </QuestionGroup>
      ))}

      <button type="submit" disabled={submiting} className="btn btn-secondary btn-full">
        {!submiting ? buttonConfig.text : buttonConfig.textSubmitting}
      </button>
    </form>
  );
};

export {Questions};
